import { useState } from "react";
import { useQuery } from "react-query";
import Utils from "../../components/Utils";
import ImportantNumber from "../../components/Utils/ImportantNumber/ImportantNumber";
import api from "../../utils/api";
import NumberSquare from "./NumberSquare/NumberSquare";

import style from "./Stats.module.css";

function StatsCard({ stats }: any) {
  return (
    <>
      <Utils.HorizontalContainer center={false} style={{ marginTop: "20px" }}>
        <ImportantNumber number={stats.answerCount} word={"Proposition"} style={{ marginRight: "30px" }} />
        <ImportantNumber
          number={stats.answerCountLastWeek}
          word={"Proposition (semaine)"}
          wordPlural={"Propositions (semaine)"}
          style={{ marginRight: "30px" }}
        />
        <ImportantNumber
          number={stats.answerCountLastMonth}
          word={"Proposition (mois)"}
          wordPlural={"Propositions (mois)"}
          style={{ marginRight: "30px" }}
        />
        <ImportantNumber
          number={stats.answerCountLastYear}
          word={"Proposition (année)"}
          wordPlural={"Propositions (année)"}
          style={{ marginRight: "30px" }}
        />
      </Utils.HorizontalContainer>
      <Utils.HorizontalContainer center={true} style={{ marginTop: "20px" }}>
        <div style={{ flex: 1 }}>
          <div className={style.statsTitleContainer}>
            <div className={style.statsTitle}>
              <span className={style.statsTitleText}>Propositions par statut</span>
            </div>
          </div>
          <Utils.HorizontalContainer>
            <NumberSquare number={stats.answerRetenue} word={"Retenue"} className={style.numberSquare} variant="green" />
            <NumberSquare number={stats.answerEnCours} word={"En cours"} className={style.numberSquare} variant="blue" />
          </Utils.HorizontalContainer>
          <Utils.HorizontalContainer style={{ marginTop: "20px" }}>
            <NumberSquare number={stats.answerNonRetenue} word={"Non-retenue"} className={style.numberSquare} variant="red" />
            <NumberSquare number={stats.answerEnAttente} word={"En attente"} className={style.numberSquare} variant="yellow" />
          </Utils.HorizontalContainer>
        </div>
        <div style={{ flex: 1 }}>
          <div className={style.statsTitleContainer}>
            <div className={style.statsTitle}>
              <span className={style.statsTitleText}>Utilisateurs</span>
            </div>
          </div>
          <Utils.HorizontalContainer>
            <NumberSquare number={stats.userCount} word={"Actifs"} className={style.numberSquare} variant="green" square={false} />
            <NumberSquare
              number={stats.lastWeek.length > 0 ? (stats.lastWeek[0].author || "Anonyme ") + " (" + stats.lastWeek[0].userAnswerCount + ")" : "-"}
              word={"Le plus actif (semaine)"}
              className={style.numberSquare}
              variant="blue"
              square={false}
            />
          </Utils.HorizontalContainer>
          <Utils.HorizontalContainer style={{ marginTop: "20px" }}>
            <NumberSquare
              number={stats.lastMonth.length > 0 ? (stats.lastMonth[0].author || "Anonyme ") + " (" + stats.lastMonth[0].userAnswerCount + ")" : "-"}
              word={"Le plus actif (mois)"}
              className={style.numberSquare}
              variant="red"
              square={false}
            />
            <NumberSquare
              number={stats.lastYear.length > 0 ? (stats.lastYear[0].author || "Anonyme ") + " (" + stats.lastYear[0].userAnswerCount + ")" : "-"}
              word={"Le plus actif (année)"}
              className={style.numberSquare}
              variant="yellow"
              square={false}
            />
          </Utils.HorizontalContainer>
        </div>
      </Utils.HorizontalContainer>
    </>
  );
}

export default function Stats() {
  // function handleToggleCategorie(e: any, cate: string) {
  //   if (e.target.checked) {
  //     setCheckCategories((b) => [...b, cate]);
  //   } else {
  //     setCheckCategories((b) => b.filter((c) => c !== cate));
  //   }
  // }

  const [category, setCategory] = useState<undefined | number>(undefined);

  function changeCategory(value: any) {
    setCategory(parseInt(value));
  }

  const { data: stats } = useQuery(["company", "stats"], api.companies.getStats);

  const { data: categoryStats } = useQuery(["categoryStats", category], () => api.categories.getCategoryStats(category || 0), {
    enabled: category !== undefined && category !== 0,
    keepPreviousData: true,
  });

  const { data: categories } = useQuery(["categories"], api.categories.getCategories);

  return stats === undefined ? (
    <>Loading</>
  ) : (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Statistiques" width="100%">
        <StatsCard stats={stats} />
      </Utils.Card>
      <Utils.Card title="Statistiques par rubrique" width="90%" style={{ marginTop: "0px" }}>
        {categories !== undefined && (
          <Utils.Select value={category} onChange={(e) => changeCategory(e)}>
            <option value={0}>Séléctionner une catégorie</option>
            {categories.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </Utils.Select>
        )}
        {categoryStats !== undefined && <StatsCard stats={categoryStats} />}
      </Utils.Card>
    </Utils.Container>
    // <Utils.Container verticalCenter={false}>
    //   <Utils.Card title="Statistiques" width="90%">
    //     <>
    //       <Utils.CustomTabs
    //         tabs={['Utilisateurs', 'Propositions']}
    //         selectedTab={selectedTabs}
    //         onChangeSelectedTab={(newTab) => setSelectedTabs(newTab as any)}
    //       >
    //         <>
    //           {selectedTabs === 'Utilisateurs' ? (
    //             <>
    //               <Utils.ImportantNumber number={5} word="Utilisateur" />

    //               <div className={style.twoColumn}>
    //                 <div className={style.column}>
    //                   <Utils.ColumnHeader title="Utilisateurs enregistrés" />

    //                   <StatComponent
    //                     percent={40}
    //                     number={2}
    //                     text="Utilisateurs sur un total de 5 ont utilisés l'application"
    //                   />

    //                   <StatComponent
    //                     percent={20}
    //                     number={1}
    //                     text="Utilisateurs actif sur un total de 5"
    //                   />

    //                   <StatComponent
    //                     percent={40}
    //                     number={2}
    //                     text="Utilisateurs actifs (semaine) sur un total de 5"
    //                   />
    //                 </div>
    //                 <div className={style.column}>
    //                   <Utils.ColumnHeader title="Utilisateurs non enregistrés" />

    //                   <StatComponent
    //                     percent={100}
    //                     number={5}
    //                     text="Utilisateurs anonymes sur un total de 5ont utilisés l'application"
    //                   />

    //                   <StatComponent
    //                     percent={200}
    //                     number={10}
    //                     text="Utilisateurs anonymes sur un total de 5 ont utilisés l'application sur un champs libre "
    //                   />
    //                 </div>
    //               </div>
    //             </>
    //           ) : selectedTabs === 'Propositions' ? (
    //             <>
    //               <div className={style.twoColumn}>
    //                 <Utils.SortMenu title="Filtrer par rubriques">
    //                   <Utils.Input
    //                     label="Rubriques"
    //                     placeholder="Chercher une catégorie"
    //                     colorReverse
    //                     value={filterSearch}
    //                     onChange={(e) => setFilterSeach(e.target.value)}
    //                   />

    //                   {categories.map((cate) => (
    //                     <Utils.Checkbox
    //                       label={cate}
    //                       checked={checkCategories.includes(cate)}
    //                       onChange={(e) => handleToggleCategorie(e, cate)}
    //                     />
    //                   ))}
    //                 </Utils.SortMenu>

    //                 <div className={style.rightDiv}>
    //                   <Utils.HorizontalContainer>
    //                     <Utils.ImportantNumber number={7} word="Proposition" />
    //                     <Utils.ImportantNumber
    //                       number={1}
    //                       word="Proposition (semaine)"
    //                       wordPlural="Propositions (semaine)"
    //                     />
    //                     <Utils.ImportantNumber
    //                       number={1}
    //                       word="Proposition (mois)"
    //                       wordPlural="Propositions (mois)"
    //                     />
    //                     <Utils.ImportantNumber
    //                       number={7}
    //                       word="Proposition (année)"
    //                       wordPlural="Propositions (années)"
    //                     />
    //                   </Utils.HorizontalContainer>

    //                   <div className={style.twoColumn}>
    //                     <div className={style.column}>
    //                       <Utils.ColumnHeader title="Propositions utilisateurs enregistrés" />

    //                       <StatComponent
    //                         percent={60}
    //                         number={5}
    //                         text="Propositions sur un total de 7 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={100}
    //                         number={1}
    //                         text="Propositions (semaines) sur un total de 1 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={100}
    //                         number={1}
    //                         text="Propositions (mois) sur un total de 1 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={100}
    //                         number={7}
    //                         text="Propositions (années) sur un total de 7 propositions"
    //                       />
    //                     </div>

    //                     <div className={style.column}>
    //                       <Utils.ColumnHeader title="Propositions utilisateurs non enregistrés" />

    //                       <StatComponent
    //                         percent={20}
    //                         number={1}
    //                         text="Propositions sur un total de 7 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={20}
    //                         number={1}
    //                         text="Propositions (semaines) sur un total de 2 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={0}
    //                         number={0}
    //                         text="Propositions (mois) sur un total de 1 propositions"
    //                       />

    //                       <StatComponent
    //                         percent={0}
    //                         number={0}
    //                         text="Propositions (années) sur un total de 7 propositions"
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </>
    //           ) : null}
    //         </>
    //       </Utils.CustomTabs>
    //     </>
    //   </Utils.Card>
    // </Utils.Container>
  );
}
