import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN } from "../utils";

export function detailledCategoriesSteps(history: any): ShepherdOptionsWithType[] {
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Options d'une rubrique</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 6;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "detailled-categories-1st",
      attachTo: { element: ".onboarding-detailledcategories-1st", on: "right" },
      advanceOn: { selector: ".onboarding-detailledcategories-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/categories");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
      Cliquez sur l'onglet <span class="important-text">Rubriques</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-2nd",
      attachTo: { element: ".onboarding-detailledcategories-2nd", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        L’icône <span class="important-text-black">Fichier CSV</span> <img src="${MY_DOMAIN}/onboarding/file-csv-solid.svg" width="14px" height="14px" /> vous permet de télécharger votre rubrique et toutes<br/>
        les idées d’amélioration rattachées à cette <span class="important-text">rubrique</span> en format tableur Excel (.csv).`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-3rd",
      attachTo: { element: ".onboarding-detailledcategories-3rd", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        // {
        //   classes: "shepherd-button shepherd-button-skip",
        //   text: cancelText,
        //   action() {
        //     history.push("/");
        //     this.cancel();
        //   },
        // },
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        En cliquant sur l’icône <span class="important-text-black">Partager</span> <img src="${MY_DOMAIN}/onboarding/share-alt-solid.svg" width="14px" height="14px" />, vous pouvez partager votre <span class="important-text">rubrique</span><br/>
        sous forme de QR code ou alors de lien URL.<br/><br/>
        Plus de détail dans le prochain tutoriel <span class="important-text">Partager une rubrique</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-4th",
      attachTo: { element: ".onboarding-detailledcategories-4th", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        Pour gagner du temps, cliquez sur l’icône <span class="important-text-black">Duppliquer</span> <img src="${MY_DOMAIN}/onboarding/clone-alt-solid.svg" width="14px" height="14px" /> pour duppliquer votre <span class="important-text">rubrique</span>.<br/>
        Cela reprendra tous les paramétrages et les champs de la <span class="important-text">rubrique</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-5th",
      attachTo: { element: ".onboarding-detailledcategories-5th", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        Cliquez sur l’icône <span class="important-text-black">Modifier</span> <img src="${MY_DOMAIN}/onboarding/pen-solid.svg" width="14px" height="14px" /> pour éditer ou modifier le <span class="important-text">nom</span>,<br/>
        <span class="important-text">l'accès</span> et les <span class="important-text">champs</span> d’une <span class="important-text">rubrique</span> existante.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-6th",
      attachTo: { element: ".onboarding-detailledcategories-6th", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
      Enfin, l'icône <span class="important-text-black">Poubelle</span> <img src="${MY_DOMAIN}/onboarding/trash-solid.svg" width="14px" height="14px" /> vous permet de supprimer votre <span class="important-text">rubrique</span>.<br/>
      <span class="important-text-red">Attention, c'est définitif !</span>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-categories-7th",
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history.push("/?onBoardingStart=2");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel <span class="important-text">"Partager une rubrique"</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
