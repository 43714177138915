import { useContext, useEffect, useState } from "react";
import Utils from "../../components/Utils";
import Information from "./Step/0_Information";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Acces from "./Step/1_Acces";
import Fields from "./Step/3_Fields";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../utils/api";
import { ShepherdTourContext } from "react-shepherd";
import classNames from "classnames";
import { Field, PostCategory, UserCategory } from "../../utils/api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { UserContextType, useUser } from "../../utils/context/User";

export interface PostField extends Omit<Field, "id"> {
  id?: number;
}

export const RubriqueSchema = Yup.object().shape({
  name: Yup.string().min(2).max(255).required(),
  description: Yup.string(),
  fields: Yup.mixed().test({
    name: "isFieldValid",
    message: "The field is not valid",
    test: (field: Field[]) => {
      let isOk = true;

      isOk = field.length > 0;

      field.forEach((f) => {
        if (f.name === "") {
          isOk = false;
        }

        if (f.type == "select" || f.type == "likert") {
          if (f.properties.choices && f.properties.choices.length <= 0) {
            isOk = false;
          }
        }
      });

      return isOk;
    },
  }),
  authMethod: Yup.string(),
});

export default function RubriqueForm() {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();
  const queryClient = useQueryClient();
  const [user] = useUser() as UserContextType;

  const [step, setStep] = useState(0);
  const [valids, setValids] = useState<boolean[]>([]);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState<boolean>(false);
  const [fieldLimit, setFieldLimit] = useState<number>(300);

  const tour = useContext(ShepherdTourContext);

  useEffect(() => {
    queryClient.prefetchQuery("company", api.companies.getMyCompany, {
      staleTime: 3 * 60 * 1000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading } = useQuery(["categories", id], () => api.categories.getOneCategory(parseInt(id || "0")), {
    enabled: id !== undefined,
    onSuccess: (data) => {
      setRubrique(data.category);
      setFieldLimit(data.fieldLimit);
    },
  });

  function handleChangeStep(newStep: number) {
    setValids((o) => {
      switch (step) {
        case 0:
          o[step] = RubriqueSchema.fields.name.isValidSync(rubrique.name);
          break;
        case 1:
          o[step] = RubriqueSchema.fields.authMethod.isValidSync(rubrique.authMethod);
          break;
        case 2:
          o[step] = RubriqueSchema.fields.fields.isValidSync(rubrique.fields);
          break;
        default:
          o[step] = false;
          break;
      }
      return o;
    });
    setStep(newStep);
  }

  const [rubrique, setRubrique] = useState<PostCategory>({
    name: "",
    description: "",
    categoryPilotes: [],
    privacy: "public",
    authMethod: "anonymous",
    fields: [{ id: Date.now(), position: 0, name: "Proposition", properties: {}, required: "required", type: "string", isFavorite: true }],
    company: user?.company,
    isActive: true,
  });

  // Validation
  const { mutate: addCategory, isLoading: isLoadingAdd } = useMutation(api.categories.createCategories, {
    onSuccess: (data) => {
      let usercategories = queryClient.getQueriesData<UserCategory[] | undefined>("user_categories")[0];

      usercategories &&
        usercategories[1]?.map((value) => {
          createUserCategory({ userId: value.user.id, categoryId: data.id });
        });

      history.push("/categories");
    },
  });

  const { mutate: createUserCategory, isLoading: isCreating } = useMutation(api.usercategories.createUserCategory, {});

  function handleAddCategory() {
    if (!RubriqueSchema.isValidSync(rubrique)) return;

    addCategory({ ...rubrique, piloteIds: rubrique.categoryPilotes.map((p) => p.id) });
  }

  useEffect(() => {
    queryClient.resetQueries("user_categories");
  }, []);

  useEffect(() => {
    let delay = setTimeout(() => setShowUpdateMessage(false), 5000);

    return () => {
      clearTimeout(delay);
    };
  }, [showUpdateMessage]);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card width="100%" title={`${rubrique !== undefined ? "Modifier" : "Créer"} une rubrique`} isLoading={isLoading}>
        <>
          <div style={{ backgroundColor: "var(--background)", width: "100%", height: "1px" }} />
          <Utils.GoBack text="Retour à la liste des rubriques" to="/categories" />

          <Utils.Step>
            <Utils.StepDot
              text="Informations"
              selected={step === 0}
              step={0}
              onClick={handleChangeStep}
              error={!RubriqueSchema.fields.name.isValidSync(rubrique.name)}
              valids={valids}
              setValids={setValids}
            />
            <Utils.StepSeparator hasText />
            <Utils.StepDot
              text="Accès & Notifications"
              selected={step === 1}
              step={1}
              onClick={handleChangeStep}
              error={!RubriqueSchema.fields.authMethod.isValidSync(rubrique.authMethod)}
              valids={valids}
              setValids={setValids}
            />
            <Utils.StepSeparator hasText />
            {/* <Utils.StepDot
              text="Pilotes"
              selected={step === 2}
              step={2}
              onClick={handleChangeStep}
            />
            <Utils.StepSeparator hasText /> */}
            <Utils.StepDot
              text="Champs"
              selected={step === 2}
              step={2}
              onClick={handleChangeStep}
              error={!RubriqueSchema.fields.fields.isValidSync(rubrique.fields)}
              valids={valids}
              setValids={setValids}
            />
          </Utils.Step>

          {step === 0 ? <Information value={rubrique} setValue={setRubrique} setShowUpdateMessage={setShowUpdateMessage} /> : null}

          {step === 1 ? <Acces value={rubrique} setValue={setRubrique} setShowUpdateMessage={setShowUpdateMessage} /> : null}

          {/* {step === 2 ? (
            <Pilotes value={rubrique} setValue={setRubrique} />
          ) : null} */}

          {step === 2 ? (
            <Fields
              value={rubrique}
              setValue={setRubrique}
              isModalViewOpen={isModalViewOpen}
              setIsModalViewOpen={setIsModalViewOpen}
              setShowUpdateMessage={setShowUpdateMessage}
              fieldLimit={fieldLimit}
            />
          ) : null}

          <Utils.HorizontalContainer
            style={{ marginTop: "2rem", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid var(--background)" }}
          >
            {step === 0 ? (
              <Utils.Button
                format="square"
                variant="gray"
                fullWidth={false}
                onClick={() => {
                  if (tour?.isActive)
                    if (tour.getCurrentStep()?.id === "create-categories-3rd") tour?.back();
                    else if (tour.getCurrentStep()?.id === "create-categories-4th") tour?.show("create-categories-2nd");
                  history.push("/categories");
                }}
              >
                Annuler
              </Utils.Button>
            ) : (
              <Utils.Button
                id="create-categories-previous"
                format="square"
                variant="gray"
                fullWidth={false}
                onClick={() => {
                  handleChangeStep(step - 1);
                }}
              >
                Précédent
              </Utils.Button>
            )}

            {!showUpdateMessage && !RubriqueSchema.isValidSync(rubrique) && (
              <span style={{ color: "var(--red)" }}>
                Les champs <span style={{ fontWeight: 600 }}>Menu déroulant</span> et <span style={{ fontWeight: 600 }}>Échelle</span> doivent avoir
                au minimum un choix possible.
              </span>
            )}
            {showUpdateMessage && <span style={{ color: "var(--green)" }}>Les modifications ont été prises en compte.</span>}

            {step !== 2 ? (
              <Utils.Button
                id="onboarding-categories-next"
                className={classNames("onboarding-categories-4th", "onboarding-categories-6th")}
                format="square"
                fullWidth={false}
                onClick={() => {
                  handleChangeStep(step + 1);
                }}
              >
                Suivant
              </Utils.Button>
            ) : rubrique.id !== undefined ? (
              <div style={{ display: "inline-flex" }}>
                <Utils.Button
                  fullWidth={false}
                  format="square"
                  style={{ marginRight: "10px", width: "50px" }}
                  onClick={() => setIsModalViewOpen(true)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Utils.Button>
                <Utils.Button
                  format="square"
                  fullWidth={false}
                  variant="secondary"
                  disabled={!RubriqueSchema.isValidSync(rubrique)}
                  onClick={() => history.push("/categories")}
                  isLoading={isLoadingAdd}
                >
                  Retour aux rubriques
                </Utils.Button>
              </div>
            ) : (
              <div style={{ display: "inline-flex" }}>
                <Utils.Button
                  fullWidth={false}
                  format="square"
                  style={{ marginRight: "10px", width: "50px" }}
                  onClick={() => setIsModalViewOpen(true)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Utils.Button>
                <Utils.Button
                  id="onboarding-categories-next-8th"
                  className="onboarding-categories-8th"
                  format="square"
                  fullWidth={false}
                  variant="secondary"
                  disabled={!RubriqueSchema.isValidSync(rubrique)}
                  onClick={handleAddCategory}
                  isLoading={isLoadingAdd}
                >
                  Terminer
                </Utils.Button>
              </div>
            )}
          </Utils.HorizontalContainer>
        </>
      </Utils.Card>
    </Utils.Container>
  );
}
