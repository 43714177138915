import { host, checkStatus } from "../api";
import { FIELDS_TYPE } from "../constantes";
import { Field } from "./_type";

interface FieldInterface {
  id: number;
  name?: string;
  type?: FIELDS_TYPE;
  required?: "required" | "unrequired" | "pilote";
  properties?: { choices?: string[]; description?: string };
  isFavorite?: boolean;
}

interface CreateFieldInterface {
  name: string;
  type: FIELDS_TYPE;
  required: "required" | "unrequired" | "pilote";
  category: number;
  properties: { choices?: string[] };
  isFavorite: boolean;
}

const fields = {
  createField: (body: CreateFieldInterface): Promise<Field> => {
    return fetch(`${host}/api/fields`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateField: (body: FieldInterface): Promise<Field> => {
    return fetch(`${host}/api/fields/${body.id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateFieldPosition: ({ id, position }: { id: number; position: number }): Promise<{ fields: Field[] }> => {
    return fetch(`${host}/api/fields/${id}/position`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ position }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteField: (id: number): Promise<string> => {
    return fetch(`${host}/api/fields/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  updateChoices: ({ id, choices }: { id: number; choices: string[] }): Promise<Field> => {
    return fetch(`${host}/api/fields/${id}/choices/all`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ choices }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  addChoice: ({ id, choice }: { id: number; choice: string }): Promise<Field> => {
    return fetch(`${host}/api/fields/${id}/choices`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ choice }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteChoice: ({ id, choice }: { id: number; choice: string }): Promise<Field> => {
    return fetch(`${host}/api/fields/${id}/choices`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ choice }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  renameChoice: ({ id, oldChoice, newChoice }: { id: number; oldChoice: string; newChoice: string }): Promise<Field> => {
    return fetch(`${host}/api/fields/${id}/choices`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ oldChoice, newChoice }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  changeDescription: ({ id, newDescription }: { id: number; newDescription: string }): Promise<Field> => {
    return fetch(`${host}/api/fields/${id}/description`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ newDescription }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default fields;
