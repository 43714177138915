import qs from "qs";
import { checkStatus, host } from "../api";
import { UserCategory } from "./_type";

const usercategories = {
  createUserCategory: ({ userId, categoryId }: { userId: number; categoryId: number }): Promise<UserCategory> => {
    return fetch(`${host}/api/usercategories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        user: {
          id: userId,
        },
        category: {
          id: categoryId,
        },
      }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getUserCategoriesByCategory: (categoryId: number): Promise<UserCategory[]> => {
    return fetch(`${host}/api/usercategories/category/${categoryId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
        "content-type": "application/json",
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteUserCategory: ({ userCategoryId }: { userCategoryId: number }): Promise<string | undefined> => {
    return fetch(`${host}/api/usercategories/${userCategoryId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
};

export default usercategories;
