import { useState } from "react";
import { ShepherdOptionsWithType, Tour } from "react-shepherd";
import { ROLES, User } from "./api/_type";

function zero(int: number): string {
  return int < 10 ? "0" + int : "" + int;
}

export function dateToString(d: string | Date): string {
  if (d === "") return "";

  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    date = new Date(d);
  }

  return `${zero(date.getDate())}/${zero(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function isFilter(baseString: string, filter: string) {
  return baseString.toLowerCase().trim().includes(filter.toLowerCase().trim());
}

export function parseHash() {
  return new URLSearchParams(window.location.hash.substr(1));
}

export function focusElement(element: React.MutableRefObject<HTMLElement | null>) {
  setTimeout(() => {
    element.current?.focus();
  }, 100);
}

export function minimumLoadingTime<T>(func: Promise<T>, time: number = 700): Promise<T> {
  let current = Date.now();

  return new Promise((resolve, reject) => {
    func
      .then((data: any) => {
        if (Date.now() - current <= time) {
          setTimeout(() => {
            resolve(data);
          }, time - (Date.now() - current));
        } else {
          resolve(data);
        }
      })
      .catch(reject);
  });
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export function compare<T>(a: T | undefined, b: T | undefined): -1 | 0 | 1 {
  if (a === undefined) return -1;
  if (b === undefined) return 1;

  // Comparaison sans prendre en compte les maj/min
  if (typeof a === "string" && typeof b === "string") {
    let otherA = a.toLowerCase().trim();
    let otherB = b.toLowerCase().trim();

    if (otherA > otherB) {
      return 1;
    } else if (otherA === otherB) {
      return 0;
    } else {
      return -1;
    }
  }

  if (a > b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

export function copyStringToClipboard(str: string) {
  var el = document.createElement("textarea");

  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";

  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export const MY_DOMAIN = window.location.protocol + "//" + window.location.hostname + (window.location.port !== "" ? `:${window.location.port}` : "");

export function useFormState<T>(initialValue: T) {
  const [internalState, setInternalState] = useState(initialValue);

  function handleInput(e: any, v?: any) {
    if (v) {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute("name") || ""]: v,
        };
      });
    } else if (e.target.getAttribute("type") === "file") {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute("name") || ""]: e.target.files,
        };
      });
    } else if (e.target.getAttribute("type") === "checkbox") {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute("name") || ""]: e.target.checked,
        };
      });
    } else {
      setInternalState((before) => {
        return {
          ...before,
          [e.target.getAttribute("name") || ""]: e.target.value,
        };
      });
    }
  }

  function resetState(newValue?: T) {
    if (newValue === undefined) {
      setInternalState(initialValue);
    } else {
      setInternalState(newValue);
    }
  }

  return [internalState, handleInput, resetState] as const;
}

export function useHashState<T extends { [key: string]: string }>(initialValue: T): readonly [T, (value: T | ((val: T) => T)) => void] {
  const [internalState, setInternalState] = useState<T>((): T => {
    // Si il n'y a rien dans l'URL
    if (window.location.hash === "") {
      // On prépare un hash avec les valeurs par défaut
      let searchParam = new URLSearchParams();

      Object.keys(initialValue).forEach((k) => {
        searchParam.set(k, initialValue[k]);
      });

      window.location.hash = searchParam.toString().replace("?", "#");

      return initialValue;
    } else {
      // Sinon on prend un searchParam avec les vrai valeurs
      let searchParam = new URLSearchParams(window.location.hash.replace("#", "?"));

      let objetKeys = Object.keys(initialValue);

      // Suppression des valeurs inutiles
      for (var key of searchParam.keys()) {
        if (!objetKeys.includes(key)) searchParam.delete(key);
      }

      let returnedObj: { [key: string]: string } = {};

      objetKeys.forEach((k) => {
        returnedObj[k] = searchParam.get(k) || initialValue[k];
      });

      objetKeys.forEach((k) => {
        searchParam.set(k, returnedObj[k]);
      });

      window.location.hash = searchParam.toString().replace("?", "#");

      return returnedObj as T;
    }
  });

  const updateState = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(internalState) : value;
      setInternalState(valueToStore);
      let searchParam = new URLSearchParams();

      Object.keys(valueToStore).forEach((k) => {
        searchParam.set(k, valueToStore[k]);
      });

      window.location.hash = searchParam.toString().replace("?", "#");
    } catch (error) {
      console.log(error);
    }
  };

  return [internalState, updateState] as const;
}

/**
 * Execute la fonction func seulement si la valeure du state n'a pas été modifiée depuis time temps
 * @param initialValue La valeure initiale du state
 * @param funcToSend Nouvelle valeure du state / fonction de mise à jour (reçoit en paramètre le state de ce moment)
 * @param time Temps de debounce
 * @returns [state, setState]
 */
export function useDebounce<T>(initialValue: T, funcToSend: (arg: T) => void, time: number = 500) {
  // Création du state local
  const [localState, setLocalState] = useState<T>(initialValue);
  const [currentTimout, setCurrentTimout] = useState<undefined | NodeJS.Timeout>(undefined);

  // Récupération du changement
  function handleChange(value: T | ((val: T) => T)): void {
    // Change le state local
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);

    // Si il y a déjà un timout, on l'annule
    if (currentTimout) {
      clearInterval(currentTimout);
    }

    // On crée un nouveau timout
    setCurrentTimout(setTimeout(() => funcToSend(valueToStore), time));
  }

  function simpleChange(value: T | ((val: T) => T)): void {
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);
  }

  return [localState, handleChange, simpleChange] as const;
}

export function dateToDatabaseString(date: Date): string {
  return `${date.getFullYear()}-${zero(date.getMonth() + 1)}-${zero(date.getDate())}T${zero(date.getHours())}:${zero(date.getMinutes())}:${zero(
    date.getSeconds()
  )}`;
}

export function arrayMove(arr: any[], old_index: number, new_index: number) {
  let newArray = [...arr];

  var element = newArray[old_index];
  newArray.splice(old_index, 1);
  newArray.splice(new_index, 0, element);

  return newArray;
}

export function arrayReplace(arr: any[], index: number, newobj: any) {
  let newArray = [...arr];

  newArray.splice(index, 1, newobj);

  return newArray;
}

export function parseQuery() {
  return new URLSearchParams(window.location.search);
}

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export function numberToPrice(num: number) {
  let number = Math.trunc(num);
  let minux = Math.trunc((num - number) * 100);

  return `${number},${zero(minux)} €`;
}

export function stringToSize(size: string | number) {
  let initial;

  if (typeof size === "string") {
    initial = parseInt(size);
  } else {
    initial = size;
  }

  let reste = initial;

  let extensions = ["o", "ko", "mo", "go", "to"];
  let currentExtension = 0;

  while (reste > 1000) {
    currentExtension++;
    reste = Math.trunc(reste / 10) / 100;
  }

  return reste + extensions[currentExtension];
}

/**
 * Format le tableau des roles d'un utilisateur dans son role le plus haut
 * @param roles
 * @returns
 */
export function formatUserRoleToString(user: User) {
  let res = user.company.name + " - ";
  if (user.roles.includes(ROLES.ROLE_COMPANY_ADMIN)) {
    return res + "Admin.";
  }

  if (user.roles.includes(ROLES.ROLE_APPLICATION_ADMIN)) {
    return res + "Admin. App";
  }

  return res + "Utilisateur";
}

export function isAdmin(user: User) {
  return user.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user.roles.includes(ROLES.ROLE_APPLICATION_ADMIN);
}

const mouseClickEvents = ["mousedown", "click", "mouseup"];

export function simulateMouseClick(element: Element | null) {
  if (element === null) return;

  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

export function startOnBoardingTour(tour: Tour | null, steps: ShepherdOptionsWithType[], start: boolean = true, startingStep?: string) {
  if (tour) {
    tour.isActive() && tour.cancel();
    tour.steps = [];
    tour.addSteps(steps);
  }

  if (start) {
    tour?.start();
  } else if (startingStep) {
    tour?.show(startingStep);
  }
}
