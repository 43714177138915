import React from "react";

import classNames from "classnames";

import style from "./Button.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isLoading?: boolean;
  variant?: "primary" | "secondary" | "green" | "gray" | "red";
  fullWidth?: boolean;
  format?: "round" | "square";
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
}

export default function Button({ isLoading, variant, fullWidth, format, containerStyle, containerClassName, ...props }: Props) {
  return (
    <div
      className={classNames(
        style.buttonContainer,
        {
          [style.notFullWidth]: fullWidth === false,
        },
        containerClassName
      )}
      style={containerStyle}
    >
      <button
        {...props}
        disabled={isLoading === true ? true : props.disabled}
        className={classNames(
          style.button,
          {
            [style.isLoading]: isLoading === true,
          },
          style[variant || "primary"],
          {
            [style.square]: format === "square",
          },
          props.className
        )}
      >
        {props.children}
      </button>
      {isLoading ? <FontAwesomeIcon className={classNames(style.icon, style[variant || "primary"])} icon={faCircleNotch} /> : null}
    </div>
  );
}
