import classNames from "classnames";
import style from "./HorizontalContainer.module.css";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  center?: boolean;
  verticalCenter?: boolean;
}

export default function HorizontalContainer({ center, verticalCenter, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        style.horizontalContainer,
        props.className,
        {
          [style.center]: center === true,
        },
        { [style.verticalCenter]: verticalCenter === true }
      )}
    >
      {props.children}
    </div>
  );
}
