import { checkStatusQuiet, host } from '../api';
import { User } from './_type';

export interface MeResponse {
  user: User;
  jwt: string;
}

const login = {
  exchangeCode: ({ code }: { code: string }): Promise<{ jwt: string }> => {
    return fetch(`${host}/api/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ code }),
    })
      .then(checkStatusQuiet)
      .then((res) => res.json());
  },
  getMe: (): Promise<MeResponse> => {
    return fetch(`${host}/api/me`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.text().then((msg: string) => {
            throw new Error(msg);
          });
        }
      })
      .then((res) => res?.json());
  },
};

export default login;
