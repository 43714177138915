import { checkStatus, host } from '../api';
import { CategoryPilote } from './_type';

const categorypilotes = {
  addCategoryPilote: ({
    user,
    category,
  }: {
    user: number;
    category: number;
  }): Promise<CategoryPilote> => {
    return fetch(`${host}/api/category_pilotes`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
        'content-type': 'application/json',
      },
      body: JSON.stringify({ user, category }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteCategoryPilote: (id: number): Promise<string> => {
    return fetch(`${host}/api/category_pilotes/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default categorypilotes;
