import style from './ColumnHeader.module.css';

interface Props {
  title: string;
}

export default function ColumnHeader({ title }: Props) {
  return (
    <div className={style.columnHeader}>
      <p>{title}</p>
    </div>
  );
}
