import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";

export function virtualBoardSteps(history: any): ShepherdOptionsWithType[] {
  const completeText: string = "Terminer";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Tableau étiquette virtuelle</span>`;

  const defaultDelay: number = 200;

  const stepsLength = 4;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "virtual-board-1st",
      attachTo: { element: ".onboarding-virtualboard-1st", on: "right" },
      advanceOn: { selector: ".onboarding-virtualboard-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/proposal");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        Cliquez sur l'onglet <span class="important-text">Propositions</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "virtual-board-2nd",
      attachTo: { element: ".onboarding-virtualboard-2nd", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-virtualboard-next-2nd"]');
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        Cliquez sur le bouton <span class="important-text">Tableau d'étiquettes virtuelles</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "virtual-board-3rd",
      attachTo: { element: ".onboarding-virtualboard-3rd", on: "right" },
      // advanceOn: { selector: ".onboarding-virtualboard-3rd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        Voici la liste des <span class="important-text">propositions</span> de votre entreprise.<br/>
        Vous pouvez utiliser le lien de cette page afin de <span class="important-text">partager</span> ou d'afficher ce tableau<br />
        où vous le souhaitez. Quiconque aura le lien de cette page sera en mesure de voir les<br />
        propositions de cette <span class="important-text">catégorie</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          window.close();
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "virtual-board-4th",
      attachTo: { element: ".onboarding-virtualboard-3rd", on: "right" },
      // attachTo: { element: ".onboarding-virtualboard-4th", on: "bottom" },
      // advanceOn: { selector: ".onboarding-virtualboard-4th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
      Les propositions sont séparées en fonction de leur statut :<br/>
      - <span class="important-text-yellow">En attente</span> : elles n'ont pas encore été traitées,<br/>
      - <span class="important-text">En cours</span> : elles sont validées, mais ne sont pas encore totalement mises en place,<br/>
      - <span class="important-text-green">Retenue</span> : elles sont validées et mises en place,<br/>
      - <span class="important-text-red">Non-retenue</span> : elles ont été refusées.<br/>
      `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          window.close();
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "virtual-board-5th",
    //   attachTo: { element: ".onboarding-virtualboard-5th", on: "bottom" },
    //   // advanceOn: { selector: ".onboarding-virtualboard-5th", event: "click" },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-back",
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: "shepherd-button shepherd-button-next",
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes: "winylo-onboarding",
    //   highlightClass: "highlight",
    //   scrollTo: false,
    //   cancelIcon: {
    //     enabled: true,
    //   },
    //   title,
    //   text: [
    //     `<span class="important-text">5 sur ${stepsLength}</span><br/>
    //     En deuxième, vous avez les propositions <span class="important-text">en cours</span>.<br/>
    //     Ce sont celles qui ont été validées par votre entreprise mais ne sont encore traitées.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       window.close();
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "virtual-board-6th",
    //   attachTo: { element: ".onboarding-virtualboard-6th", on: "top" },
    //   // advanceOn: { selector: ".onboarding-virtualboard-6th", event: "click" },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-back",
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: "shepherd-button shepherd-button-next",
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes: "winylo-onboarding",
    //   highlightClass: "highlight",
    //   scrollTo: false,
    //   cancelIcon: {
    //     enabled: true,
    //   },
    //   title,
    //   text: [
    //     `<span class="important-text">6 sur ${stepsLength}</span><br/>
    //     En troisième, vous avez les propositions <span class="important-text">en attente</span>.<br/>
    //     Celles-ci n'ont pas encore été acceptées ou refusées par votre entreprise.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       window.close();
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "virtual-board-7th",
    //   attachTo: { element: ".onboarding-virtualboard-7th", on: "top" },
    //   // advanceOn: { selector: ".onboarding-virtualboard-7th", event: "click" },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         window.scrollTo(0, 0);
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-back",
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: "shepherd-button shepherd-button-next",
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes: "winylo-onboarding",
    //   highlightClass: "highlight",
    //   scrollTo: true,
    //   cancelIcon: {
    //     enabled: true,
    //   },
    //   title,
    //   text: [
    //     `<span class="important-text">7 sur ${stepsLength}</span><br/>
    //     Enfin, vous avez les propositions <span class="important-text">non retenues</span>.<br/>
    //     Elles n'ont pas été acceptées par votre entreprise.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       window.close();
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "virtual-board-8th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-complete",
          text: completeText,
          action() {
            this.complete();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel !<br/><br/>
        Vous pouvez maintenant fermer cet onglet.`,
      ],
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ...
  ];
}
