import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Utils from "../../../components/Utils";
import { Field, PostCategory } from "../../../utils/api/_type";
import api from "../../../utils/api";

interface ModalDescriptionProps {
  isModalDescriptionOpen: Field | undefined;
  setIsModalDescriptionOpen: React.Dispatch<React.SetStateAction<Field | undefined>>;
  value: PostCategory;
  setValue: (value: React.SetStateAction<PostCategory>) => void;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDescription = React.forwardRef<HTMLTextAreaElement, ModalDescriptionProps>(
  ({ isModalDescriptionOpen, setIsModalDescriptionOpen, value, setValue, setShowUpdateMessage, setFocus }: ModalDescriptionProps, ref) => {
    const [descriptionTextArea, setDescriptionTextArea] = useState("");

    const { mutate: changeDescription } = useMutation(api.fields.changeDescription, {
      onSuccess: () => {
        setShowUpdateMessage(true);
      },
    });

    function handleChangeDescription() {
      if (isModalDescriptionOpen === undefined) return;

      setValue((b) => ({
        ...b,
        fields: b.fields.map((f) =>
          f.id === isModalDescriptionOpen.id
            ? {
                ...f,
                properties: {
                  ...f.properties,
                  description: descriptionTextArea,
                },
              }
            : f
        ),
      }));

      setIsModalDescriptionOpen((b) =>
        b === undefined
          ? b
          : {
              ...b,
              properties: {
                ...b.properties,
                description: descriptionTextArea,
              },
            }
      );

      if (value.id !== undefined) {
        changeDescription({
          id: isModalDescriptionOpen.id,
          newDescription: descriptionTextArea,
        });
      } else {
        setShowUpdateMessage(true);
      }
    }

    React.useEffect(() => {
      setDescriptionTextArea(isModalDescriptionOpen?.properties.description || "");
    }, [isModalDescriptionOpen]);

    return (
      <Utils.Modal isOpen={isModalDescriptionOpen !== undefined} closable onClose={() => setIsModalDescriptionOpen(undefined)} title="Description">
        <Utils.Textarea
          ref={ref}
          value={descriptionTextArea}
          onChange={(e) => setDescriptionTextArea(e.target.value)}
          label={"Description"}
          rows={10}
          autoFocus
          style={{ marginBottom: "1.5rem" }}
        />
        <div style={{ height: "1.5rem", width: "100%" }} /> {/* Si je met la margin sur le bouton la modal pète un cable jsp */}
        <Utils.Button onClick={handleChangeDescription} format="square" fullWidth={false}>
          Modifier la description
        </Utils.Button>
      </Utils.Modal>
    );
  }
);

export default ModalDescription;
