import classNames from 'classnames';
import React from 'react';

import style from './ListItem.module.css';

interface Props {
  text?: string;
  children?: React.ReactNode;
}

export default function ListItem({
  text,
  children,
}: Props): React.ReactElement {
  return (
    <div className={classNames(style.listItem)}>
      <span className={style.text}>{text}</span>
      {children}
    </div>
  );
}
