import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faHeart, faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Utils from "../../../../components/Utils";
import { arrayMove, MY_DOMAIN } from "../../../../utils/utils";
import { Field, PostCategory } from "../../../../utils/api/_type";
import { UserContextType, useUser } from "../../../../utils/context/User";
import { useMutation } from "react-query";
import api from "../../../../utils/api";
import { ShepherdTourContext } from "react-shepherd";

import style from "./FieldItem.module.css";

interface FieldItemProps {
  index: number;
  field: Field;
  category: PostCategory;
  setCategory: React.Dispatch<React.SetStateAction<PostCategory>>;
  setIsModalMediaOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalDescriptionOpen: React.Dispatch<React.SetStateAction<Field | undefined>>;
  setIsModalChoiceOpen: React.Dispatch<React.SetStateAction<Field | undefined>>;
  handleCloneChamp: (field: Field) => void;
}

export default function FieldItem({
  index,
  field,
  category,
  setCategory,
  setIsModalMediaOpen,
  setIsModalDescriptionOpen,
  setShowUpdateMessage,
  setIsModalChoiceOpen,
  handleCloneChamp,
}: FieldItemProps) {
  const [user] = useUser() as UserContextType;
  const [debounceState, setDebounceState] = useState<{
    [key: string]: NodeJS.Timeout | undefined;
  }>({});

  // Sheperd onboarding tour
  const tour = useContext(ShepherdTourContext);

  const { mutate: updateField } = useMutation(api.fields.updateField, {
    onSuccess: () => {
      setShowUpdateMessage(true);
    },
  });

  const { mutate: deleteField } = useMutation(api.fields.deleteField, {
    onSuccess: () => {
      setShowUpdateMessage(true);
    },
  });

  const { mutate: updateFieldPosition } = useMutation(api.fields.updateFieldPosition, {
    onSuccess: () => {
      setShowUpdateMessage(true);
    },
  });

  function handleDelete(index: number, id: number) {
    setCategory((b) => ({
      ...b,
      fields: b.fields.filter((_val, i) => i !== index),
    }));

    if (category.id !== undefined) {
      deleteField(id);
    }
  }

  // Changement de champs favoris
  function handleChangeFav(field: Field) {
    if (field.isFavorite) return;

    if (category.id === undefined) {
      setCategory((b) => {
        return {
          ...b,
          fields: b.fields.map((f) =>
            f.id === field.id
              ? {
                  ...f,
                  isFavorite: true,
                }
              : { ...f, isFavorite: false }
          ),
        };
      });
    } else {
      setCategory((b) => {
        return {
          ...b,
          fields: b.fields.map((f) =>
            f.id === field.id
              ? {
                  ...f,
                  isFavorite: true,
                }
              : { ...f, isFavorite: false }
          ),
        };
      });

      updateField({ id: field.id, isFavorite: true });
    }
  }

  function handleChangeValue(id: number, champ: string, val: string) {
    if (category.id !== undefined) {
      if (champ !== "name") {
        updateField({
          id,
          [champ]: val,
          properties: champ === "type" && (val === "select" || val === "likert") ? { choices: [] } : val === "title" ? { description: "" } : {},
        });
      } else {
        if (val !== "") {
          if (debounceState["" + id] !== undefined) {
            clearTimeout(debounceState["" + id] as any);
          }

          let timout = setTimeout(() => {
            updateField({ id, name: val });
          }, 500);

          setDebounceState((b) => ({
            ...b,
            ["" + id]: timout,
          }));
        }
      }
    }
    setCategory((b) => {
      return {
        ...b,
        fields: b.fields.map((f) =>
          f.id === id
            ? {
                ...f,
                [champ]: val,
                properties: f.properties.choices || f.properties.description ? f.properties : { choices: [], description: "" },
              }
            : f
        ),
      };
    });
  }

  function handleGoUpDown(index: number, id: number, offset: -1 | 1) {
    if (offset > 0 ? index === category.fields.length - 1 : index === 0) return;
    setCategory((b) => ({ ...b, fields: arrayMove(b.fields, index, index + offset) }));

    if (category.id !== undefined) {
      updateFieldPosition({ id, position: offset > 0 ? index + 2 : index });
    }
  }

  return (
    <>
      <div className={style.gridCol} style={{ paddingTop: "5px" }}>
        <FontAwesomeIcon
          icon={faHeart}
          className={classNames(style.favIcon, {
            [style.selected]: field.isFavorite === true,
          })}
          onClick={(e) => handleChangeFav(field)}
        />
      </div>
      <div className={style.gridCol}>
        <Utils.Input value={field.name} onChange={(e) => handleChangeValue(field.id, "name", e.target.value)} isInvalid={field.name === ""} />
      </div>
      <div className={style.gridCol}>
        <Utils.Select value={field.required} onChange={(newVal) => handleChangeValue(field.id, "required", newVal)}>
          <option value="required">Requis</option>
          <option value="unrequired">Non requis</option>
          <option value="pilote">Dédié aux administrateurs</option>
        </Utils.Select>
      </div>
      <div className={style.gridCol}>
        <Utils.Select value={field.type} onChange={(newVal) => handleChangeValue(field.id, "type", newVal)}>
          <option value="title">Informations</option>
          <option value="likert">Échelle</option>
          <option value="string">Texte</option>
          <option value="bool">Case à cocher</option>
          <option value="int">Nombre</option>
          <option value="date">Date</option>
          {user?.company.properties?.access?.capture === 1 ? (
            <option value="file">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Photos & Vidéos</span>
                <img src={`${MY_DOMAIN}/img/media-icon.png`} style={{ height: "1.5rem", width: "1.5rem", marginLeft: "0.5rem" }} />
              </div>
            </option>
          ) : (
            <option value="file">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalMediaOpen(true);
                }}
                style={{ display: "flex", color: "grey" }}
              >
                <span>Photos & Vidéos</span>
                <img src={`${MY_DOMAIN}/img/media-icon-lock.png`} style={{ height: "1.5rem", width: "1.5rem", marginLeft: "0.5rem" }} />
                <FontAwesomeIcon icon={faLock} style={{ marginLeft: "0.5rem", alignSelf: "center" }} />
              </div>
            </option>
          )}

          {!tour?.isActive() ? <option value="select">Menu déroulant</option> : <></>}
        </Utils.Select>
      </div>
      {category.fields.map((f) => f.type === "select" || f.type === "likert" || f.type === "title").includes(true) ? (
        <div className={classNames(style.gridCol, style.specialBtn)}>
          {field.type === "select" || field.type === "likert" ? (
            <Utils.Button
              format="square"
              // fullWidth={false}
              containerStyle={{ marginTop: "0px" }}
              onClick={() => setIsModalChoiceOpen(field)}
            >
              Choix
            </Utils.Button>
          ) : field.type === "title" ? (
            <Utils.Button
              format="square"
              // fullWidth={false}
              containerStyle={{ marginTop: "0px" }}
              onClick={() => setIsModalDescriptionOpen(field)}
            >
              Description
            </Utils.Button>
          ) : null}
        </div>
      ) : null}
      {!tour?.isActive() && (
        <div className={style.gridCol}>
          <div className={style.iconContainer}>
            <Utils.UpDown
              canGoUp={index !== 0}
              canGoDown={index !== category.fields.length - 1}
              goUp={() => handleGoUpDown(index, field.id, -1)}
              goDown={() => handleGoUpDown(index, field.id, 1)}
            />
            <FontAwesomeIcon icon={faClone} className={style.trash} onClick={() => handleCloneChamp(field)} />
            <FontAwesomeIcon icon={faTrash} className={style.trash} onClick={() => handleDelete(index, field.id)} />
          </div>
        </div>
      )}
    </>
  );
}
