import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";

export function shareQRCodeSteps(history: any): ShepherdOptionsWithType[] {
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Partager une rubrique</span>`;

  const onComputerText: string = "un ordinateur : ";
  const onPhoneText: string = "un téléphone :";

  const defaultDelay: number = 200;

  const stepsLength = 9;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "share-qrcode-1st",
      attachTo: { element: ".onboarding-shareqrcode-1st", on: "right" },
      advanceOn: { selector: ".onboarding-shareqrcode-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/categories");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        Cliquez sur l'onglet <span class="important-text">Rubriques</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-qrcode-2nd",
      attachTo: { element: ".onboarding-shareqrcode-2nd", on: "right" },
      advanceOn: { selector: ".onboarding-shareqrcode-2nd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('svg[id="onboarding-shareqrcode-next-2nd"]');
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-shareqrcode-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        Cliquez sur l'icône <span class="important-text-black">Partager</span> <img src="${MY_DOMAIN}/onboarding/share-alt-solid.svg" width="14px" height="14px" />.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-qrcode-3rd",
      attachTo: { element: ".onboarding-shareqrcode-3rd", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            var element = document.querySelector('button[id="onboarding-shareqrcode-previous"]');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        Voici le <span class="important-text">QR Code</span> de la rubrique.<br/>
        Il vous sert à remonter une idée.<br/>
        <br/>
        <span class="important-text">Saisissez votre smartphone<br/>
        et scannez le QR Code ci-joint.</span><br/>
        <br/>
        En dessous, deux boutons vous permettent<br/>
        de <span class="important-text">télécharger et imprimer</span><br/>
        votre QR code en format PDF ou image.<br/>
        <br/>
        <span class="important-text-black">Objectif :</span> l'afficher dans votre entreprise<br/>
        pour permettre à vos collaborateurs de le scanner.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "share-qrcode-4th",
    //   attachTo: { element: ".onboarding-shareqrcode-4th", on: "right" },
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-back",
    //       text: backText,
    //       action() {
    //         this.back();
    //       },
    //     },
    //     {
    //       classes: "shepherd-button shepherd-button-next",
    //       text: nextText,
    //       action() {
    //         this.next();
    //       },
    //     },
    //   ],
    //   classes: "winylo-onboarding",
    //   highlightClass: "highlight",
    //   scrollTo: true,
    //   title,
    //   text: [
    //     `<span class="important-text">4 sur ${stepsLength}</span><br/>
    //     Ces boutons vous permettent de <span class="important-text">télécharger</span><br/>
    //      votre QR code en format PDF ou image.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       history.push("/");
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-qrcode-5th",
      attachTo: { element: ".onboarding-shareqrcode-5th", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        Ceci est le <span class="important-text">lien de redirection</span> de votre rubrique,<br/>
         il peut aussi être partagé par exemple par mail.<br/><br/>
         Cliquez sur <span class="important-text-yellow">Copier le lien</span><br/>
         et essayez de le coller dans votre navigateur.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-qrcode-6th",
      attachTo: { element: ".onboarding-shareqrcode-6th", on: "right" },
      // advanceOn: { selector: ".onboarding-shareqrcode-6th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        Enfin, cette zone vous permet d'ajouter<br/>
        un texte sous votre QR code en <span class="important-text">format PDF</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "share-qrcode-7th",
      attachTo: { element: ".onboarding-shareqrcode-7th", on: "left" },
      advanceOn: { selector: ".onboarding-shareqrcode-7th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-shareqrcode-next-7th"]');
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
      Appuyez sur <span class="important-text">enregistrer</span> afin que vos modifications soient sauvegardées.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "push-idea-1st",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            // this.hide();
            var element = document.querySelector('svg[id="onboarding-shareqrcode-next-2nd"]');
            simulateMouseClick(element);
            // this.show();
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">7 sur ${stepsLength}</span><br/><br/>
        Après l'avoir partagé, il est possible d'émettre des propositions sur votre rubrique depuis
        <table class="pushidea-table">
          <thead>
            <tr>
              <th>${onComputerText}</th>
              <th>${onPhoneText}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="onboarding-pushidea-with-image-description"> 
                    <div class="onboarding-pushidea-computer-description">
                      <img src="${MY_DOMAIN}/onboarding/pushidea_0_computer.png" class="onboarding-pushidea-computer-image"/><br/>
                      <p class="onboarding-pushidea-2nd-text">Utilisez le lien dans votre navigateur adin d'accéder au <span class="important-text">formulaire.</span></p>
                    </div>
                </div>
              </td>
              <td>
              <div class="onboarding-pushidea-with-image-description"> 
                  <div class="onboarding-pushidea-phone-description">
                  <div style="display:flex;">
                    <img src="${MY_DOMAIN}/onboarding/pushidea_0_phone.png" class="onboarding-pushidea-phone-image"/>
                    <img src="${MY_DOMAIN}/onboarding/arrow-right-long-solid.png" class="onboarding-pushidea-phone-arrow-image"/>
                    <img src="${MY_DOMAIN}/onboarding/pushidea_2_phone.png" class="onboarding-pushidea-phone-image"/>
                    </div><br/>
                    <p class="onboarding-pushidea-2nd-text">Scannez le QR Code de votre rubrique afin d'accéder au <span class="important-text">formulaire.</span></p>
                  </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>`,
      ],
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "push-idea-5th",
      attachTo: { element: ".onboarding-pushidea-5th", on: "right" },
      advanceOn: { selector: ".onboarding-pushidea-5th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/proposal");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">8 sur ${stepsLength}</span><br/>
      Cliquez sur l'onglet <span class="important-text">Propositions</span> dans le menu à gauche.`,
      ],
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "push-idea-6th",
      attachTo: { element: ".onboarding-pushidea-6th", on: "left" },
      advanceOn: { selector: ".onboarding-pushidea-6th", event: "click" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">9 sur ${stepsLength}</span><br/>
        Si vous avez bien suivi ce tutoriel,<br/>
       vous devriez voir votre <span class="important-text">proposition</span> ici.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "push-idea-7th",
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history.push("/?onBoardingStart=4");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel sur le <span class="important-text">"Traitement des propositions"</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // {
    //   id: "share-qrcode-8th",
    //   canClickTarget: false,
    //   beforeShowPromise: function () {
    //     return new Promise(function (resolve) {
    //       setTimeout(function () {
    //         resolve(null);
    //       }, defaultDelay);
    //     });
    //   },
    //   buttons: [
    //     {
    //       classes: "shepherd-button shepherd-button-nexttutorial",
    //       text: nextTutoText,
    //       action() {
    //         history.push("/?onBoardingStart=3");
    //         this.complete();
    //       },
    //     },
    //     // {
    //     //   classes: "shepherd-button shepherd-button-complete",
    //     //   text: completeText,
    //     //   action() {
    //     //     this.complete();
    //     //   },
    //     // },
    //   ],
    //   classes: "winylo-onboarding",
    //   highlightClass: "highlight",
    //   scrollTo: true,
    //   title: `<span>Félicitations !</span>`,
    //   text: [
    //     `Vous avez terminé le tutoriel !<br/>
    //   Vous pouvez vous arrêter là ou bien continuer<br/>
    //   en lançant le tutoriel <span class="important-text">"Remonter une idée"</span>.`,
    //   ],
    //   popperOptions: {
    //     modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
    //   },
    //   modalOverlayOpeningPadding: overlayPadding,
    //   modalOverlayOpeningRadius: overlatRadius,
    //   when: {
    //     cancel: function () {
    //       history.push("/");
    //     },
    //   },
    // }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
