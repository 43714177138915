import classNames from "classnames";
import cssStyle from "./NumberSquare.module.css";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  number: string | number;
  word: string;
  variant?: "blue" | "green" | "red" | "yellow";
  square?: boolean;
}

export default function NumberSquare(props: Props) {
  return (
    <div {...props} className={classNames(cssStyle.number, props.className)}>
      {props.square === undefined || props.square ? <span className={classNames(cssStyle.square, cssStyle[props.variant || "blue"])}></span> : null}
      <span className={cssStyle.word}>{props.word}</span>
      <div className={cssStyle.numberColor}>{props.number}</div>
    </div>
  );
}
