import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./UpDown.module.css";

import classNames from "classnames";

interface Props {
  canGoUp?: boolean;
  canGoDown?: boolean;
  goUp?: () => void;
  goDown?: () => void;
}

export default function UpDown({ canGoUp = true, canGoDown = true, goUp, goDown }: Props) {
  return (
    <div className={style.upDown}>
      <FontAwesomeIcon icon={faCaretUp} className={classNames(style.size, { [style.enabled]: canGoUp })} onClick={canGoUp ? goUp : undefined} />
      <FontAwesomeIcon
        icon={faCaretDown}
        className={classNames(style.size, { [style.enabled]: canGoDown })}
        onClick={canGoDown ? goDown : undefined}
      />
    </div>
  );
}
