import { SmallCategory } from '../../utils/api/_type';

import { AwesomeQRCode } from '@awesomeqr/react';
import { useEffect, useState } from 'react';
import { MY_DOMAIN } from '../../utils/utils';

import { UserContextType, useUser } from '../../utils/context/User';
import { checkStatusQuiet, host_core } from '../../utils/api';

import style from './QRCodeCategory.module.css';

interface Props {
  category: SmallCategory;
  qrRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function QRCodeCategory({ category, qrRef }: Props) {
  const [user] = useUser() as UserContextType;

  const [qrOptions, setQrOptions] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    fetch(host_core + '/api/companies/brand-image/' + user?.company.id)
      .then(checkStatusQuiet)
      .then((res) => res.blob())
      .then((blob) => {
        setQrOptions({
          text: `${MY_DOMAIN}/c/${category.token}`,
          colorLight: '#ffffff',
          dotScale: 1,
          logoImage: URL.createObjectURL(blob),
          logoScale: 0.3,
          logoCornerRadius: 50,
          margin: 0,
        });
      })
      .catch((err) => {
        setQrOptions({
          text: `${MY_DOMAIN}/c/${category.token}`,
          colorLight: '#ffffff',
          dotScale: 1,
          logoScale: 0.3,
          logoCornerRadius: 50,
          margin: 0,
        });
      });
  }, [category, user]);

  return (
    <div className={style.container}>
      <p
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: 0,
        }}
      >
        {category.name}
      </p>
      <div ref={qrRef} className={style.qr}>
        {qrOptions === undefined ? null : <AwesomeQRCode options={qrOptions} />}
      </div>
    </div>
  );
}
