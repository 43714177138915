import { Link, useLocation } from "react-router-dom";

import style from "./SidePannel.module.css";
import { UserContextType, useUser } from "../../../utils/context/User";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faClipboard, faThLarge, faChartPie, faHome, faBug } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import { ROLES } from "../../../utils/api/_type";
import Modal from "../Modal/Modal";
import Utils from "..";
import { useMutation } from "react-query";
import api from "../../../utils/api";

export default function SidePannel({ children }: { children: React.ReactElement }) {
  const [user] = useUser() as UserContextType;
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isBugOpen, setIsBugOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [bugFormValue, setBugFormValue] = useState<string>("");

  const { mutate: sendBug } = useMutation(api.bugs.sendBug, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setBugFormValue("");
      setIsBugOpen(false);
      setIsLoading(false);
    },
  });

  function MenuRow({ path, icon, text, role, className }: { path: string; icon: IconDefinition; text: string; role?: ROLES; className?: string }) {
    return role === undefined || user?.roles.includes(role) ? (
      <Link
        to={path}
        className={classNames(
          {
            [style.selectedRow]: location.pathname === path,
          },
          style.menuRow,
          className
        )}
      >
        <span className={style.iconContainer}>
          <FontAwesomeIcon icon={icon} className={style.icon} />{" "}
        </span>
        <span className={style.menuRowText}>{text}</span>
      </Link>
    ) : null;
  }

  function handleCancelBugForm() {
    setBugFormValue("");
    setIsBugOpen(false);
  }

  function handleSubmitBugForm() {
    sendBug({ userId: user?.id, message: bugFormValue });
  }

  return (
    <div className={style.widthContainer}>
      <div className={classNames(style.left, { [style.opened]: isMenuOpen })}>
        {isMenuOpen ? (
          <img
            src={process.env.PUBLIC_URL + "/img/burger-close.svg"}
            alt="fermer"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/img/burger-open.svg"}
            alt="ouvrir"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <MenuRow path="/" icon={faHome} text="Accueil" />
          <MenuRow
            path="/proposal"
            icon={faClipboard}
            text="Propositions"
            className={classNames("onboarding-virtualboard-1st", "onboarding-pushidea-5th", "onboarding-detailledproposals-1st")}
          />
          <MenuRow
            path="/categories"
            icon={faThLarge}
            text="Rubriques"
            className={classNames("onboarding-categories-1st", "onboarding-shareqrcode-1st", "onboarding-detailledcategories-1st")}
          />
          <MenuRow path="/stats" icon={faChartPie} text="Statistiques" />
        </div>
        <div className={style.bug} onClick={() => setIsBugOpen(true)}>
          <a className={classNames(style.menuRow)}>
            <span className={style.iconContainer}>
              <FontAwesomeIcon icon={faBug} className={style.icon} />{" "}
            </span>
            <span className={style.menuRowText}>Remonter un bug</span>
          </a>
        </div>
      </div>
      <div className={classNames(style.right, { [style.opened]: isMenuOpen })}>{children}</div>

      <Utils.Modal title="Remonter un bug" isOpen={isBugOpen} onClose={() => setIsBugOpen(false)}>
        <div className={style.bugFormContainer}>
          {/* <Utils.Input label="Problème"></Utils.Input> */}
          <Utils.Textarea label="Description du problème" value={bugFormValue} onChange={(e) => setBugFormValue(e.target.value)}></Utils.Textarea>
          <Utils.BottomButton>
            <Utils.Button variant="gray" fullWidth={false} format="square" onClick={() => handleCancelBugForm()}>
              Annuler
            </Utils.Button>
            <Utils.Button
              isLoading={isLoading}
              format="square"
              fullWidth={false}
              onClick={() => handleSubmitBugForm()}
              containerStyle={{ marginLeft: "auto" }}
            >
              Envoyer
            </Utils.Button>
          </Utils.BottomButton>
        </div>
      </Utils.Modal>
    </div>
  );
}
