export const JWT_PERIOD = 40 * 60 * 1000;

export const FIELDS: { name: string; code: FIELDS_TYPE }[] = [
  { name: "Case à cocher", code: "bool" },
  { name: "Nombre", code: "int" },
  { name: "Texte", code: "string" },
  { name: "Menu déroulant", code: "select" },
];
export type FIELDS_TYPE = "bool" | "int" | "date" | "string" | "select" | "file" | "title" | "likert";

export type PRIVACY = "public" | "private";

export const Privacy: { name: string; code: PRIVACY }[] = [
  {
    name: "Publique",
    code: "public",
  },
  {
    name: "Privé",
    code: "private",
  },
];

export type AUTH_METHOD = "anonymous" | "connection" | "text" | "anything";
export const AuthMethod: { name: string; code: AUTH_METHOD }[] = [
  {
    name: "Anonyme",
    code: "anonymous",
  },
  {
    name: "Connexion requise",
    code: "connection",
  },
  {
    name: "Champs libre",
    code: "text",
  },
  {
    name: "Libre",
    code: "anything",
  },
];

export const UserOptionColors = [
  "#bde0ff",
  "#ffddbd",
  "#e4f1cb",
  "#dbbdff",
  "#bdf1ff",
  "#ffbdfd",
  "#bdf7ff",
  "#c4f8ef",
  "#bde0ff",
  "#ffbdd6",
  "#c8f4d5",
  "#bde0ff",
  "#ffbdfd",
];
