import { host, checkStatus, checkStatusWithoutError } from "../api";
import { AUTH_METHOD, PRIVACY } from "../constantes";
import qs from "qs";
import { Category, Answer, PageItem, ICategoriesFilter } from "./_type";

interface StatsResponse {
  userCount: number;
  answerCount: number;
  answerCountLastWeek: number;
  answerCountLastMonth: number;
  answerCountLastYear: number;
  answerEnAttente: number;
  answerEnCours: number;
  answerNonRetenue: number;
  answerRetenue: number;
  lastMonth: [{ userAnswerCount: number; author?: string }];
  lastWeek: [{ userAnswerCount: number; author?: string }];
  lastYear: [{ userAnswerCount: number; author?: string }];
}

const forms = {
  getCategories: (): Promise<Category[]> => {
    return fetch(`${host}/api/categories`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  createCategories: (body: {
    name: string;
    privacy: "public" | "private" | "none";
    authMethod: "anonymous" | "connection" | "text" | "anything" | "none";
    description: string;
    fields: {
      name: string;
      type: "bool" | "int" | "date" | "string" | "select";
      required: "required" | "unrequired" | "pilote";
      isFavorite: boolean;
    };
    piloteIds: number[];
    color?: string | null;
    showLogo?: boolean | null;
    noticeAnswer?: boolean | null;
  }): Promise<Category> => {
    return fetch(`${host}/api/categories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getOneCategory: (id: number): Promise<{ category: Category; fieldLimit: number }> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteCategory: (id: number): Promise<string> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  getCategoryWithToken: (token: string): Promise<Category> => {
    return fetch(`${host}/api/categories/${token}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatusWithoutError)
      .then((res) => {
        if (res.status === 423) {
          throw new Error(res.statusText);
        }

        if (res.status === 403) {
          throw new Error(res.statusText);
        }

        return res.json();
      });
  },
  updateCategory: ({
    id,
    body,
  }: {
    id: number;
    body: {
      name?: string;
      privacy?: PRIVACY;
      authMethod?: AUTH_METHOD;
      customText?: null | string;
      active?: boolean;
      description?: string;
      color?: string;
      showLogo?: boolean;
      noticeAnswer?: boolean;
      isActive?: boolean;
    };
  }): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  addStatusToCategory: ({ id, name }: { id: number; name: string }): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}/status`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCategoryAnswers: ({ page, id }: { page: number; id: number }): Promise<PageItem<Answer>> => {
    return fetch(`${host}/api/categories/${id}/answers?page=${page}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCategoriesFiltered: ({ page, filters }: { page: number; filters: ICategoriesFilter }): Promise<PageItem<Category>> => {
    return fetch(`${host}/api/categories/filters?${qs.stringify({ page, ...filters })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCategoryStats: (id: number): Promise<StatsResponse> => {
    return fetch(`${host}/api/categories/${id}/stats`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  downloadCsv: ({ id, dates }: { id: number; dates?: { from: string; to: string } }): Promise<Blob> => {
    return fetch(`${host}/api/categories/${id}/csv`, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      }),
      body: JSON.stringify(dates),
    })
      .then(checkStatus)
      .then((res) => res.blob());
  },
  changeDefaultStatus: ({ id, defaultStatus }: { id: number; defaultStatus: number }): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}/default-status`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ defaultStatus }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  clone: ({ id, cloneToken, copyOf }: { id: number; cloneToken: boolean; copyOf: boolean }): Promise<Category> => {
    return fetch(`${host}/api/categories/${id}/clone`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ cloneToken, copyOf }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default forms;
