import React from "react";

import ReactModal from "react-modal";

import style from "./Modal.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  closable?: boolean;
  onClose?: () => void;
  width?: string;
  title?: string;
  verticalCenter?: boolean;
  autoWidth?: boolean;
  closeButtonID?: string;
  childrenStyle?: string;
  containerClassName?: string;
}

export default function Modal({
  children,
  isOpen = true,
  closable = true,
  onClose,
  width,
  title,
  verticalCenter,
  autoWidth,
  closeButtonID,
  childrenStyle,
  containerClassName,
}: Props) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classNames(style.blackDiv, {
        [style.verticalCenter]: verticalCenter !== false,
      })}
      className={classNames(style.modal, containerClassName, {
        [style.modalWidth]: autoWidth !== true,
      })}
      onRequestClose={closable ? onClose : undefined}
      style={{ content: { width } }}
    >
      {closable ? <FontAwesomeIcon id={closeButtonID} className={style.closeIcon} icon={faTimesCircle} onClick={onClose} /> : null}
      {title !== undefined ? <p className={style.modalTitle}>{title}</p> : null}

      <div className={classNames(style.childrenContainer, childrenStyle)}>{children}</div>
    </ReactModal>
  );
}
