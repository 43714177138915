import ReadonlyElement from "../../components/ReadonlyElement/ReadonlyElement";
import style from "./ReadonlyProposal.module.css";

import { useInfiniteQuery, useQueryClient } from "react-query";
import api from "../../utils/api";
import { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ShepherdTourContext } from "react-shepherd";
import classNames from "classnames";
import { formatUserRoleToString, MY_DOMAIN, startOnBoardingTour, useDebounce } from "../../utils/utils";
import { virtualBoardSteps } from "../../utils/onboardingtours/virtual-board.steps";
import { ReactComponent as UserCircle } from "../../svg/user-circle.svg";
import { ReactComponent as CaretDown } from "../../svg/caret-down.svg";
import { ReactComponent as PowerOff } from "../../svg/power-off.svg";
import qs from "qs";
import Utils from "../../components/Utils";
import { faCaretLeft, faCaretRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { UserContextType, useUser } from "../../utils/context/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialFiltersValue = {
  global: "",
};

const notLoggedRoute = ["/login", "/register", "/"];

export default function ReadonlyProposal() {
  const [isTopBarMini, setIsTopBarMini] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [totalAnswerLength, setTotalAnswerLength] = useState<number>(0);
  const [filters, setFilters] = useState<{
    global: string;
  }>(initialFiltersValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [searchValue, setSearchValue] = useDebounce("", (val) => {
    setFilters((old) => ({ ...old, global: val }));
  });

  const menu = useRef<any>(null);
  const menuButton = useRef<any>(null);

  const [user, setUser] = useUser() as UserContextType;
  const tour = useContext(ShepherdTourContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const location = useLocation();

  const { token } = useParams<{ token: string }>();

  const {
    data: answers,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(["answers", filters], ({ pageParam = 1 }) => api.answers.getAnswersReadonly({ token, page: pageParam, filters }), {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    onSuccess: (answersPagination) => {
      setTotalAnswerLength(answersPagination.pages[0].pagination.totalCount || 0);
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.current < lastPage.pagination.endPage) {
        return lastPage.pagination.current + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
  });

  function getAnswers() {
    if (answers === undefined || answers.pages === undefined) return [];

    return answers.pages.map((page) => page.items).flat();
  }

  function getProposalOfStatus(status: string) {
    return getAnswers().filter((answer) => answer.status.name === status);
  }

  function logout() {
    localStorage.removeItem("jwt");
    setUser(undefined);
    setIsMenuOpen(false);
    queryClient.clear();

    if (!notLoggedRoute.includes(location.pathname) && !location.pathname.startsWith("/c/")) {
      history.push("/login#redirect=" + location.pathname);
    }
  }

  function toggleMenu() {
    setIsMenuOpen((before) => !before);
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    let isOnBoarding = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoarding;
    if (isOnBoarding === "true") {
      startOnBoardingTour(tour, virtualBoardSteps(history), false, "virtual-board-3rd");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <div className={classNames(style.verticalContainer, "onboarding-virtualboard-3rd")}>
      {isTopBarMini ? (
        <div className={style.miniTopCard} onClick={() => setIsTopBarMini(false)} title="Agrandir">
          <div className={classNames(style.miniTopCard, style.sticky)}>
            {screenSize.width > 1825 ? (
              <div className={style.brandLogoMini}>
                <img className={style.brandLogoIcon} src={`${MY_DOMAIN}/kaizenLogo.png`} alt="Logo" />
              </div>
            ) : (
              <div className={style.brandLogoMicro}>
                <FontAwesomeIcon icon={faCaretRight} className={style.brandLogoIconMicro} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classNames(style.card, style.topCard, style.sticky)}>
          <FontAwesomeIcon
            onClick={() => setIsTopBarMini(true)}
            icon={faCaretLeft}
            style={{ position: "absolute", fontSize: "2rem", left: "-20px", color: "var(--secondary-text)" }}
            title="Réduire"
          />
          <div className={style.brandLogo} onClick={() => (window.location.href = "/")}>
            <img className={style.brandLogoIcon} src={`${MY_DOMAIN}/kaizenLogo.png`} alt="Logo" />
            <span className={style.brandLogoText}>{"Boite à idées digitale"}</span>
          </div>
          <div className={style.searchContainer}>
            <Utils.Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inverted={true}
              icon={faSearch}
              placeholder="Rechercher"
              width={screenSize.width >= 400 ? "250px" : "100%"}
              clear={() => {
                setSearchValue("");
              }}
            />
            <div className={style.importantNumber}>
              <Utils.ImportantNumber
                style={{ marginLeft: "20px" }}
                word={screenSize.width >= 1350 ? "Proposition" : ""}
                wordPlural={screenSize.width >= 1350 ? "Propositions" : ""}
                number={totalAnswerLength}
              />
            </div>
          </div>
          {/* c/c de topbar pour aller plus vite mais un composant commun serait top mieux, à garder de côté */}
          <div className={style.rightItems}>
            {user && (
              <>
                <div className={style.userInfoContainer}>
                  <span className={style.userName}>{user.firstname + " " + user.lastname.substring(0, 1) + "."}</span>
                  <span className={style.userRole}>{formatUserRoleToString(user)}</span>
                </div>
                <div className={style.collapseButton} ref={menuButton} onClick={toggleMenu}>
                  <UserCircle className={classNames(style.icon, style.circle)} />
                  <CaretDown
                    className={classNames(style.icon, style.caretDown, {
                      [style.reverseCaretDown]: isMenuOpen === true,
                    })}
                  />
                </div>
                {isMenuOpen && (
                  <div className={style.menu} ref={menu}>
                    <p
                      onClick={() => {
                        if (process.env.REACT_APP_URL_CORE) {
                          window.location.href = process.env.REACT_APP_URL_CORE;
                        }
                      }}
                    >
                      Retour aux apps
                    </p>
                    {/* <div className={style.separator}></div> */}
                    <p className={style.disconnect} onClick={logout}>
                      <PowerOff />
                      Déconnexion
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <div className={classNames(style.card, "onboarding-virtualboard-4th")}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>Retenue</h1>
        </div>

        <div className={style.cardRow}>
          {getProposalOfStatus("Retenue").map((answer) => (
            <ReadonlyElement key={answer.id} answer={answer} />
          ))}
        </div>
      </div>

      <div className={classNames(style.card, "onboarding-virtualboard-5th")}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>En cours</h1>
        </div>

        <div className={style.cardRow}>
          {getProposalOfStatus("En cours").map((answer) => (
            <ReadonlyElement key={answer.id} answer={answer} />
          ))}
        </div>
      </div>

      <div className={classNames(style.card, "onboarding-virtualboard-6th")}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>En attente</h1>
        </div>

        <div className={style.cardRow}>
          {getProposalOfStatus("En attente").map((answer) => (
            <ReadonlyElement key={answer.id} answer={answer} />
          ))}
        </div>
      </div>

      <div className={classNames(style.card, "onboarding-virtualboard-7th")}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>Non retenue</h1>
        </div>

        <div className={style.cardRow}>
          {getProposalOfStatus("Non-retenue").map((answer) => (
            <ReadonlyElement key={answer.id} answer={answer} />
          ))}
        </div>
      </div>
    </div>
  );
}
