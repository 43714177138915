import style from "./Error.module.css";
import { parseHash } from "../../utils/utils";
import { useEffect, useState } from "react";

export default function Error() {
  const [code, setCode] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    let hash = parseHash();

    setCode(hash.get("code"));
    setMessage(hash.get("message"));
  }, []);

  return (
    <div className={style.card}>
      <div className={style.titleContainer}>
        <span className={style.title}>{`Erreur ${code!} : ${message!}`}</span>
      </div>
      <div className={style.separator}></div>

      <div className={style.cardHeader}>
        <img className={style.image} src={require("./winytriste.png")} />
      </div>
    </div>
  );
}
