import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import Toastify from "toastify-js";
import { SmallCategory } from "../../utils/api/_type";
import { copyStringToClipboard, MY_DOMAIN } from "../../utils/utils";
import QRCodeCategory from "../QRCodeCategory/QRCodeCategory";
import Utils from "../Utils";
import "react-quill/dist/quill.snow.css";
import { useMutation, useQueryClient } from "react-query";
import api from "../../utils/api";

import PDFComponent from "../PDFComponent/PDFComponent";
import ReactDOM from "react-dom";
import html2pdf from "html2pdf.js";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  category: SmallCategory;
}

export default function ModalShare({ isOpen, onClose, category }: Props) {
  const [customText, setCustomText] = useState(category.customText || "");
  const queryClient = useQueryClient();

  function copyString() {
    copyStringToClipboard(MY_DOMAIN + "/c/" + category.token);

    Toastify({
      text: "Adresse copiée dans le presse papier",
      duration: 1500,
      close: true,
      gravity: "bottom", // `top` or `bottom`
      position: "center", // `left`, `center` or `right`
      backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
    }).showToast();
  }

  const { mutate: updateCategory } = useMutation(api.categories.updateCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
    },
  });

  function handleSave() {
    updateCategory({ id: category.id, body: { customText: customText } });
    onClose();
  }

  // Gestion du téléchargement
  const qrRef = useRef<HTMLDivElement | null>(null);

  function handleSaveAndDownload() {
    updateCategory(
      { id: category.id, body: { customText: customText } },
      {
        onSuccess: (data) => {
          downloadPDF(data);
        },
      }
    );
  }

  function downloadPDF(cate: SmallCategory) {
    const div = document.createElement("div");
    let qrComponent = qrRef.current?.children[0].children[0] as HTMLDivElement;
    let qr = qrComponent.style.backgroundImage.replace('url("', "").replace('")', "");

    ReactDOM.render(<PDFComponent type="category" data={cate} qr={qr} />, div);

    html2pdf()
      .set({
        margin: 2,
        image: { type: "jpeg", quality: 1 },
        filename: `ImpressionQRCode_${cate?.name}.pdf`,
        jsPDF: { orientation: "portrait" },
      })
      .from(div)
      .save();
  }

  function downloadQR() {
    let a = document.createElement("a");
    let qr = qrRef.current?.children[0].children[0] as HTMLDivElement;
    a.href = qr.style.backgroundImage.replace('url("', "").replace('")', "");

    a.download = `QRCode_${category?.name}.png`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Utils.Modal title="Partager la rubrique" isOpen={isOpen} onClose={onClose} closable width="600px">
      <>
        <div className="onboarding-shareqrcode-3rd">
          <QRCodeCategory category={category} qrRef={qrRef} />

          <div className="onboarding-shareqrcode-4th" style={{ display: "flex", justifyContent: "center" }}>
            <Utils.Button format="square" fullWidth={false} onClick={downloadQR} style={{ marginRight: "5px" }}>
              Télécharger le QR Code
            </Utils.Button>
            <Utils.Button format="square" fullWidth={false} onClick={handleSaveAndDownload} style={{ marginLeft: "5px" }}>
              Télécharger le PDF
            </Utils.Button>
          </div>
        </div>

        <div className="onboarding-shareqrcode-5th">
          <Utils.Input value={MY_DOMAIN + "/c/" + category.token} onChange={() => {}} />
          <Utils.Button variant="secondary" format="square" fullWidth={false} onClick={copyString}>
            Copier le lien
          </Utils.Button>
        </div>

        <ReactQuill
          className="onboarding-shareqrcode-6th"
          theme="snow"
          value={customText}
          onChange={setCustomText}
          placeholder="Texte qui sera afficher sous le QR Code lors du téléchargement du PDF"
        />

        <Utils.BottomButton>
          <Utils.Button id="onboarding-shareqrcode-previous" variant="gray" fullWidth={false} format="square" onClick={onClose}>
            Annuler
          </Utils.Button>

          <Utils.Button
            id="onboarding-shareqrcode-next-7th"
            className="onboarding-shareqrcode-7th"
            format="square"
            fullWidth={false}
            onClick={handleSave}
            containerStyle={{ marginLeft: "auto" }}
          >
            Enregistrer
          </Utils.Button>
        </Utils.BottomButton>
      </>
    </Utils.Modal>
  );
}
