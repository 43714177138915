import style from "./StepDot.module.css";

import classNames from "classnames";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface Props {
  selected?: boolean;
  text?: string;
  onClick?: (step: any) => void;
  unlocked?: boolean;
  step?: any;
  error?: boolean;
  valids: boolean[];
  setValids: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export default function StepDot({ selected = false, text, unlocked = true, onClick, step, error = false, valids, setValids }: Props) {
  const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);

  function isValid(): boolean {
    return hasBeenSelected && !error;
  }
  useEffect(() => {
    if (selected) {
      setHasBeenSelected(true);
    }
  }, [selected]);

  useEffect(() => {
    setValids((o) => {
      o[step] = isValid();

      return o;
    });
  });

  return (
    <div
      className={classNames(style.stepDotContainer, {
        [style.selected]: selected,
        [style.error]: error && !selected,
      })}
    >
      <div
        className={classNames(style.stepDot, valids[step] && style.valid, { [style.unlocked]: unlocked })}
        onClick={unlocked && onClick !== undefined ? () => onClick(step) : undefined}
      >
        {!valids[step] ? selected || unlocked ? <div className={style.selectedDot} /> : null : <FontAwesomeIcon color="white" icon={faCheck} />}
      </div>
      {text !== undefined ? <p className={style.stepDotText}>{text}</p> : null}
    </div>
  );
}
