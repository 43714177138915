import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import FormFields from "../../components/FormFields/FormFields";
import Utils from "../../components/Utils";
import api from "../../utils/api";

export default function FormProposal() {
  const { token } = useParams<{ token: string }>();

  const { data: category, isLoading, isError } = useQuery(["categoriesToken", token], () => api.categories.getCategoryWithToken(token));
  const { mutate: addProposal, isLoading: isButtonLoading } = useMutation(api.answers.addAnswersWithToken);

  const { mutate: sendFile, isLoading: isSendingFile } = useMutation(api.answers.postAnswerFile);

  function handleSubmit(body: { answerItems: { field: number; value: string | boolean | File }[]; freeField?: string }) {
    let otherAnswers: { field: number; value: string | boolean }[] = [];
    let filesAnswers: { field: number; value: File }[] = [];

    addProposal(
      {
        token,
        body: {
          answerItems: body.answerItems.filter((i) => {
            if (i.value instanceof File) {
              filesAnswers = filesAnswers.concat(i as { field: number; value: File });
            } else {
              return i;
            }
          }) as {
            field: number;
            value: string | boolean;
          }[],
          freeField: body.freeField,
        },
      },
      {
        onSuccess: (response) => {
          filesAnswers.map((f) => {
            sendFile({ id: response.id, field: f.field, file: f.value });
          });
          setIsProposalSended(true);
        },
      }
    );
  }

  const [isProposalSended, setIsProposalSended] = useState(false);

  function handleClose() {
    window.location.reload();
  }

  useEffect(() => {
    if (category?.color2nd) {
      document.body.style.backgroundColor = `#${category.color2nd}`;
    }
  }, [category]);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card
        title={category?.name}
        titleStyle={{ color: category?.color ? `#${category?.color}` : undefined }}
        titleCenter
        isLoading={isLoading}
      >
        <>
          {isError ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
              Cette rubrique n'est pas active. Pour plus d'informations veuillez prendre contact avec l'administrateur de celle-ci
            </div>
          ) : category !== undefined ? (
            <FormFields category={category} onSubmit={handleSubmit} isLoading={isButtonLoading} />
          ) : (
            <div style={{ height: "100px" }}></div>
          )}
        </>
      </Utils.Card>
      <Utils.ConfirmModal
        isOpen={isProposalSended}
        onClose={handleClose}
        closable
        title="Proposition ajoutée"
        text="Votre proposition a été ajoutée à la liste! Vous pouvez fermer cet onglet ou ajouter une autre proposition"
      />
    </Utils.Container>
  );
}
