import { faCircleNotch, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { useMutation } from "react-query";
import api from "../../utils/api";
import { Answer, AnswerItem } from "../../utils/api/_type";
import { FIELDS_TYPE } from "../../utils/constantes";
import { UserContextType, useUser } from "../../utils/context/User";
import { compare, dateToString, isAdmin } from "../../utils/utils";
import Utils from "../Utils";
import Modal from "../Utils/Modal/Modal";
import StepSeparator from "../Utils/Step/StepSeparator";
import style from "./ReadonlyElement.module.css";

interface Props {
  answer: Answer;
}

export default function ReadonlyElement({ answer }: Props) {
  const [user] = useUser() as UserContextType;

  function getCreator(answer: Answer) {
    if (answer.creator !== null) {
      return (
        <>
          {answer.creator.firstname} {answer.creator.lastname}
        </>
      );
    } else if (answer.freeField !== null) {
      return <>{answer.freeField}</>;
    } else {
      return "Anonyme";
    }
  }

  function formatColor() {
    if (answer.status.name === "En attente") {
      return style.secondary;
    } else if (answer.status.name === "En cours") {
      return style.primary;
    } else if (answer.status.name === "Retenue") {
      return style.green;
    } else if (answer.status.name === "Non-retenue") {
      return style.red;
    } else if (answer.status.name === "Archivée") {
      return style.red;
    }
  }

  function formatAnswerItem(answerItem: AnswerItem, fileOpen?: boolean) {
    switch (answerItem.type) {
      case "select":
      case "likert":
      case "int":
      case "string":
        return answerItem.value || "-";
      case "date":
        return answerItem.value ? dateToString(answerItem.value) : "-";
      case "bool":
        return <Utils.Checkbox readOnly checked={answerItem.value === "1"} />;
      case "file":
        return (
          <>
            {answerItem.value.length <= 0 ? (
              "-"
            ) : (
              <img
                className={style.img}
                style={fileOpen ? { cursor: "zoom-in" } : {}}
                src={answerItem.value}
                alt=" Cette image n'existe pas."
                onClick={() => fileOpen && window.open(answerItem.value)}
              />
            )}
          </>
        );
    }
  }

  function getField() {
    let field = answer.answerItems.find((aI) => aI.field.isFavorite === true);

    if (field !== undefined) return field;

    return answer.answerItems.sort((a, b) => compare(a.field.position, b.field.position))[0];
  }

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [fullAnswer, setFullAnswer] = useState<Answer | undefined>(undefined);

  const { mutate: getFullAnswer, isLoading } = useMutation(api.answers.getAnswer, {
    onSuccess: (data) => {
      setFullAnswer(data);
    },
  });

  function handleClick() {
    setDetailsOpen(true);
    getFullAnswer({ id: answer.id });
  }

  function canSeeFavoriteField() {
    return getField().field.required !== "pilote" ? true : false /*user && isAdmin(user)*/;
  }

  return (
    <>
      <div className={style.readonly} onClick={() => handleClick()}>
        {answer.response ? (
          <div
            className={classNames(style.indicator, style.commentIndicator, formatColor())}
            title={`A reçu une réponse de ${answer.responseBy?.firstname} ${answer.responseBy?.lastname}`}
          >
            <FontAwesomeIcon icon={faComment} />
          </div>
        ) : (
          <div className={classNames(style.indicator, style.colorIndicator, formatColor())} title={answer.status.name} />
        )}

        <div className={style.top}>
          <div>
            <p className={style.title}>{getCreator(answer)}</p>
            <p className={style.createdDate}>{dateToString(answer.createdAt)}</p>
            <div className={style.categoryName} style={{ marginTop: "10px", marginBottom: "10px" }}>
              {answer.category.name}
            </div>
          </div>
        </div>

        {answer.answerItems.length >= 1 && canSeeFavoriteField() ? (
          <>
            <Utils.List>
              <Utils.ListSubItem>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={style.bigDot} />
                  <span className={style.fieldName}>{getField().field.name}</span>
                </div>
              </Utils.ListSubItem>
              <Utils.ListSubItem>
                <div className={style.pilotContainer}>{formatAnswerItem(getField())}</div>
              </Utils.ListSubItem>
            </Utils.List>
          </>
        ) : null}
      </div>

      <Modal childrenStyle={style.modalChildren} autoWidth isOpen={detailsOpen} onClose={() => setDetailsOpen(false)} closable>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <Utils.Card style={{ marginTop: "10px", marginBottom: 0 }}>
            {!isLoading ? (
              <>
                <div className={style.title}>{getCreator(answer)}</div>
                <div className={style.createdDate}>{dateToString(answer.createdAt)}</div>
                <div className={style.categoryName}>{answer.category.name}</div>
                {/* la liste margin-top: 25px normalement */}
                {fullAnswer && (
                  <Utils.List>
                    {fullAnswer.answerItems
                      .filter((answerItem) => answerItem.field.required !== "pilote")
                      .sort((a, b) => compare(a.field.position, b.field.position))
                      .map((answerItem) => {
                        return (
                          <div key={answerItem.id} className={style.modalItem}>
                            <span className={style.fieldName}>{answerItem.field.name}</span>
                            {formatAnswerItem(answerItem, true)}
                          </div>
                        );
                      })}
                  </Utils.List>
                )}
                <div className={style.modalItem} style={{ marginBottom: "10px" }}>
                  <span style={{ fontWeight: "600" }}>Status</span>
                  <div>{fullAnswer?.status.name}</div>
                </div>
                {fullAnswer?.response && (
                  <>
                    <Utils.Textarea
                      resizable={false}
                      readOnly
                      label="Réponse"
                      value={fullAnswer && fullAnswer.response !== null ? fullAnswer.response : ""}
                    />
                    {fullAnswer && fullAnswer.responseBy && (
                      <span className={style.responseBy}>
                        Répondu par {fullAnswer.responseBy.firstname} {fullAnswer.responseBy.lastname}
                      </span>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className={style.loaderContainer}>
                <FontAwesomeIcon className={style.icon} icon={faCircleNotch} />
              </div>
            )}
          </Utils.Card>
        </div>
      </Modal>
    </>
  );
}
