import classNames from 'classnames';

import style from './TabsContainer.module.css';

interface Props {
  tabs: string[];
  selectedTab: string;
  onChangeSelectedTab: (newTab: string) => void;
  children: React.ReactElement;
}

export default function CustomTabs({
  children,
  tabs,
  selectedTab,
  onChangeSelectedTab,
}: Props) {
  return (
    <div className={style.tabContainer}>
      <div className={style.top}>
        {tabs.map((tab) => (
          <div
            className={classNames(style.tab, {
              [style.selectedTab]: selectedTab === tab,
            })}
            onClick={() => onChangeSelectedTab(tab)}
          >
            <p>{tab}</p>
          </div>
        ))}
      </div>

      <div className={style.childrenContainer}>{children}</div>
    </div>
  );
}
