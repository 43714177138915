import { faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MultiValueGenericProps } from "react-select";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import HelpIcon from "../../../components/HelpIcon/HelpIcon";
import StyledSelect from "../../../components/StyledSelect/StyledSelect";
import Utils from "../../../components/Utils";
import api from "../../../utils/api";
import { PostCategory, UserCategory, UserOption } from "../../../utils/api/_type";
import { AUTH_METHOD, UserOptionColors } from "../../../utils/constantes";
import { useDebounce } from "../../../utils/utils";

import style from "../RubriqueForm.module.css";
import selectStyle from "../selectOptions.module.css";

interface Props {
  value: PostCategory;
  setValue: React.Dispatch<React.SetStateAction<PostCategory>>;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Acces({ value, setValue, setShowUpdateMessage }: Props) {
  const queryClient = useQueryClient();
  const { mutate: updateCategory } = useMutation(api.categories.updateCategory, {
    onSuccess: () => {
      setShowUpdateMessage(true);
    },
  });

  const { data: companyUsers } = useQuery("company_users", api.companies.getCompanyUsers);
  const { data: userCategories } = useQuery("user_categories", () => api.usercategories.getUserCategoriesByCategory(value.id as number), {
    onSuccess: (data) => {},
    enabled: !!value.id,
  });

  const [, setSelectedUsersFilterInput] = useState<UserOption[]>([]);
  const [selectedUsersFilter, setSelectedUsersFilter] = useDebounce<UserOption[]>(
    [],
    (selectedUsers) => {
      setSelectedUsersFilterInput(selectedUsers);
    },
    500
  );

  const { mutate: createUserCategory } = useMutation(api.usercategories.createUserCategory, {
    onSuccess: (data) => {
      queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
        if (!old) return undefined;
        old.unshift(data);
        return old;
      });
    },
  });

  const { mutate: deleteUserCategory } = useMutation(api.usercategories.deleteUserCategory, {
    onMutate: (variables) => {
      deleteUserCategoryFromQueriesData(variables.userCategoryId);

      // queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
      //   if (!old) return undefined;
      //   return old.filter((uc) => uc.id !== variables.userCategoryId);
      // });
    },
  });

  function handleChangeAuthMethod(newVal: string) {
    setValue((b: any) => ({ ...b, authMethod: newVal }));

    if (value.id !== undefined) {
      updateCategory({
        id: value.id,
        body: { authMethod: newVal as AUTH_METHOD },
      });
    }
  }

  // function handleChangePrivacy(newVal: string) {
  //   setValue((b: any) => ({ ...b, privacy: newVal }));

  //   if (value.id !== undefined) {
  //     updateCategory({
  //       id: value.id,
  //       body: { privacy: newVal as PRIVACY },
  //     });
  //   }
  // }

  function handleCreateClick() {
    const selectedUsersFilterTemp: UserOption[] = structuredClone(selectedUsersFilter);
    setSelectedUsersFilter([]);
    if (value.id) {
      selectedUsersFilterTemp.forEach((suf) => {
        createUserCategory({ userId: suf.value, categoryId: value.id as number });
      });
    } else {
      selectedUsersFilterTemp.map((suf) => {
        queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
          if (!old) old = [];

          old.unshift({ id: suf.value, user: { id: suf.value, firstname: suf.label, lastname: suf.label }, category: { id: -1 }, type: "admin" });
          return old;
        });
      });
    }
  }

  function handleDeleteClick(userCategory: UserCategory) {
    if (value.id) {
      deleteUserCategory({ userCategoryId: userCategory.id });
    } else {
      deleteUserCategoryFromQueriesData(userCategory.id);
    }
  }

  function deleteUserCategoryFromQueriesData(id: number) {
    queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
      if (!old) return undefined;
      return old.filter((uc) => uc.id !== id);
    });
  }

  const formatOptionUser = (data: UserOption, formatOptionLabelMeta: FormatOptionLabelMeta<UserOption>) => {
    return (
      <div className={selectStyle.option}>
        <FontAwesomeIcon className={selectStyle.userIcon} icon={faUser} />
        <span style={{ marginLeft: "0.5rem" }}>{data.label}</span>
      </div>
    );
  };

  const MultiValueContainer = (props: MultiValueGenericProps<UserOption>) => {
    return (
      <div
        className={selectStyle.multiValueContainer}
        style={{
          backgroundColor: props.data.color,
        }}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div
      className="onboarding-categories-5th"
      style={{
        width: "100%",
        maxWidth: "600px",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "40vh",
      }}
    >
      {/* <Utils.Select
        helpIcon={faQuestionCircle}
        helpContent="Détermine si votre rubrique peut être vue de tous ou non."
        label="Visibilité"
        value={value.privacy}
        onChange={handleChangePrivacy}
      >
        <option value="public">Publique</option>
        <option value="private">Privée</option>
      </Utils.Select> */}

      <Utils.Select
        // helpIcon={faQuestionCircle}
        // helpContent="Cela vous permet de choisir de quelle manière des propositions peuvent être ajoutées à votre rubrique."
        label={
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0.25rem" }}>
            <span style={{ fontWeight: "600" }}>Méthode d'authentification</span>
            <div style={{ position: "relative", marginLeft: "0.5rem" }}>
              <HelpIcon
                title={"Type d'accès"}
                content={"Cela vous permet de choisir de quelle manière des propositions peuvent être ajoutées à votre rubrique."}
                iconSize="0.75rem"
                containerSize="1.5rem"
                width="30rem"
                left="-15rem"
                bottom="40px"
              />
            </div>
          </div>
        }
        value={value.authMethod}
        onChange={handleChangeAuthMethod}
      >
        {/* <option value="anything">Au choix</option> */}
        <option key="anonymous" value="anonymous">
          Anonyme
        </option>
        <option key="text" value="text">
          Libre: sans authentification
        </option>
        <option key="connection" value="connection">
          Connecté: nécessite un compte utilisateur
        </option>
      </Utils.Select>

      <div className={style.switchContainer}>
        <Utils.Switch
          checked={!!value.noticeAnswer}
          onClick={() => {
            value.id && updateCategory({ id: value.id as number, body: { noticeAnswer: !value.noticeAnswer } });
            setValue((b) => ({ ...b, noticeAnswer: !value.noticeAnswer }));
          }}
        />
        <div className={style.switchLabel}>Notifier les utilisateurs en cas de réponse à leurs propositions</div>
      </div>

      <span style={{ fontWeight: 600 }}>Personnes à notifier en cas de propositions sur cette catégorie</span>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StyledSelect
          options={
            companyUsers
              ? [
                  ...(companyUsers
                    ?.filter((u) => {
                      return !userCategories?.some((uc) => uc.user.id === u.id);
                    })
                    .map((u) => {
                      return {
                        value: u.id,
                        label: `${u.firstname} ${u.lastname}`,
                        color: UserOptionColors[Math.floor(Math.random() * UserOptionColors.length)],
                      };
                    }) || []),
                ].flat()
              : undefined
          }
          className={selectStyle.searchSelect}
          value={selectedUsersFilter || []}
          isSearchable={true}
          onChange={(value) => {
            setSelectedUsersFilter(value as UserOption[]);
          }}
          isMulti
          closeMenuOnSelect={false}
          formatOptionLabel={formatOptionUser as any}
          components={{ MultiValueContainer } as any}
          noOptionsText={"Aucun utilisateur trouvé"}
        />
        <Utils.Button fullWidth={false} format="square" /*loading={isCreating}*/ onClick={() => handleCreateClick()}>
          Ajouter
        </Utils.Button>
      </div>
      <div className={style.accessGridHeaderContainer}>
        <div className={style.accessGridHeader}>Nom</div>
        <div className={style.accessGridHeader}>Prénom</div>
        <div className={style.accessGridHeader}></div>
        <div className={style.separator} />
      </div>
      <div className={style.accessGridContainer}>
        {userCategories?.map((uc, index) => {
          return (
            <>
              {index !== 0 && <div className={style.separator} />}
              <div className={style.accessGridElement}>{uc.user.lastname}</div>
              <div className={style.accessGridElement}>{uc.user.firstname}</div>
              <div className={style.actionIconContainer}>
                <div className={style.actionIcon} onClick={() => handleDeleteClick(uc)}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
