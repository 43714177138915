import React from 'react';

import style from './Modal.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import Modal from './Modal';

interface Props {
  isOpen?: boolean;
  closable?: boolean;
  onClose?: () => void;
  title: string;
  text: string;
}

export default function ConfirmModal(props: Props) {
  return (
    <Modal {...props}>
      <FontAwesomeIcon icon={faCheckCircle} className={style.confirmIcon} />
      <p className={style.title}>{props.title}</p>
      <p className={style.text}>{props.text}</p>
    </Modal>
  );
}
