import React from 'react';

import style from './PDFComponent.module.css';

import { ReactComponent as WinyloBande } from './bande_winylo.svg';

export default function PDFComponent({
  type,
  data,
  qr,
}: {
  type: string;
  data: any;
  qr: string;
}) {
  return (
    <>
      <WinyloBande className={style.bande} />
      <div className={style.container}>
        <div>
          <h1 className={style.title}>
            {type === 'category' ? data.name : 'Entreprise'}
          </h1>
          <img src={qr} alt="QRCode" className={style.qrCode} />
          <div
            className={style.text}
            dangerouslySetInnerHTML={{ __html: data.customText }}
          ></div>
        </div>
      </div>
    </>
  );
}
