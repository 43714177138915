import React from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PopOver from "../PopOver/PopOver";
import style from "./Textarea.module.css";

interface Props extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string | React.ReactNode;
  isInvalid?: boolean;
  invalidColor?: string | null;
  helpIcon?: IconDefinition;
  helpContent?: string;
  resizable?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(({ invalidColor = "var(--red)", resizable = true, ...props }: Props, ref) => {
  return (
    <div>
      {!!props.label ? (
        <div className={style.labelContainer}>
          <label className={style.label}>{props.label}</label>
          {props.helpIcon !== undefined && (
            <PopOver className={style.popOver} content={props.helpContent}>
              <FontAwesomeIcon icon={props.helpIcon} className={style.helpIcon} />
            </PopOver>
          )}
        </div>
      ) : null}
      <textarea
        {...props}
        ref={ref}
        className={classNames(style.textarea, props.className, {
          [style.isInvalid]: props.isInvalid,
        })}
        style={{ resize: resizable ? "block" : "none", border: props.isInvalid ? `1px solid #${invalidColor}` : "unset" }}
      >
        {props.children}
      </textarea>
    </div>
  );
});

export default Textarea;
