import React from "react";

import { useHistory, useLocation } from "react-router";
import { ROLES } from "../utils/api/_type";
import { HasCheckLoginContextType, useHasCheckLogin } from "../utils/context/HasCheckLogin";
import { UserContextType, useUser } from "../utils/context/User";

interface Props {
  children: any;
  roles?: ROLES[];
}

export default function NeedLogin({ children, roles }: Props): React.ReactElement {
  const history = useHistory();
  const location = useLocation();

  const [user] = useUser() as UserContextType;
  const [hasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  if (hasCheckLogin) {
    if (user !== undefined) {
      if (roles === undefined) {
        return children;
      } else {
        for (const role of roles) {
          if (user.roles.includes(role)) {
            return children;
          }
        }
        history.push("/proposal/readonly");
        return <></>;
      }
    } else {
      history.push("/login#redirect=" + location.pathname);
      return <></>;
    }
  } else {
    return <></>;
  }
}
