import React, { useState } from "react";
import { useMutation } from "react-query";
import FormFields from "../../../components/FormFields/FormFields";
import Utils from "../../../components/Utils";
import api from "../../../utils/api";
import { Field, PostCategory } from "../../../utils/api/_type";
import { MY_DOMAIN } from "../../../utils/utils";
import ModalChoice from "../Components/ModalChoice";
import ModalDescription from "../Components/ModalDescription";
import FieldItem from "../Components/FieldItem/FieldItem";

import style from "../RubriqueForm.module.css";
import classNames from "classnames";

interface Props {
  value: PostCategory;
  setValue: React.Dispatch<React.SetStateAction<PostCategory>>;
  isModalViewOpen: boolean;
  setIsModalViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
  fieldLimit: number;
}

export default function Fields({ value, setValue, isModalViewOpen, setIsModalViewOpen, setShowUpdateMessage, fieldLimit }: Props) {
  const [isModalMediaOpen, setIsModalMediaOpen] = useState<boolean>(false);
  // Modal properties
  const [isModalChoiceOpen, setIsModalChoiceOpen] = useState<Field | undefined>(undefined);
  const [isModalDescriptionOpen, setIsModalDescriptionOpen] = useState<Field | undefined>(undefined);
  const descriptionTextAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const choicesInputRef = React.useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState<boolean>(true);

  function handleAddChamp() {
    if (value.id !== undefined) {
      addField({
        category: value.id,
        name: "Champ" + (value.fields.length + 1),
        properties: {},
        required: "unrequired",
        type: "string",
        isFavorite: value.fields.length === 0,
      });
    } else {
      setValue((b) => ({
        ...b,
        fields: [
          ...b.fields,
          {
            id: Date.now(),
            position: b.fields.length,
            name: "Champ" + (b.fields.length + 1),
            properties: {},
            required: "unrequired",
            type: "string",
            isFavorite: value.fields.length === 0,
          },
        ],
      }));
    }
  }

  function handleCloneChamp(field: Field) {
    if (value.id !== undefined) {
      addField({
        category: value.id,
        name: `${field.name} (copie)`,
        properties: field.properties,
        required: field.required,
        type: field.type,
        isFavorite: value.fields.length === 0,
      });
    } else {
      setValue((b) => ({
        ...b,
        fields: [
          ...b.fields,
          {
            id: Date.now(),
            position: b.fields.length,
            name: `${field.name} (copie)`,
            properties: field.properties,
            required: field.required,
            type: field.type,
            isFavorite: value.fields.length === 0,
          },
        ],
      }));
    }
  }

  const { mutate: addField } = useMutation(api.fields.createField, {
    onSuccess: (data) => {
      setShowUpdateMessage(true);

      setValue((b) => ({
        ...b,
        fields: [...b.fields, data],
      }));
    },
  });

  React.useEffect(() => {
    if (choicesInputRef && choicesInputRef.current) {
      choicesInputRef.current.focus();
      return;
    }

    if (descriptionTextAreaRef && descriptionTextAreaRef.current) {
      descriptionTextAreaRef.current.focus();
      return;
    }
  }, [focus, isModalChoiceOpen]);

  return (
    <div className={style.container}>
      <div className={classNames([style.gridContainer, "onboarding-categories-7th"])}>
        <div className={style.gridHead}>
          <div />
          <div className={style.tableHead}>Nom du champ</div>
          <div className={style.tableHead}>Paramètre du champ</div>
          <div className={style.tableHead}>Type du champ</div>
          {value.fields.map((f) => f.type === "select" || f.type === "likert" || f.type === "title").includes(true) ? (
            <div style={{ width: "9.375rem" }}></div>
          ) : null}
          <div style={{ width: "5rem" }} />
        </div>
        {value.fields.map((field, index) => (
          <div key={field.id} className={style.gridRow}>
            <FieldItem
              index={index}
              field={field}
              category={value}
              setCategory={setValue}
              setIsModalMediaOpen={setIsModalMediaOpen}
              setShowUpdateMessage={setShowUpdateMessage}
              setIsModalDescriptionOpen={setIsModalDescriptionOpen}
              setIsModalChoiceOpen={setIsModalChoiceOpen}
              handleCloneChamp={handleCloneChamp}
            />
          </div>
        ))}
        {value.fields.length < fieldLimit && (
          <div className={style.buttonContainer}>
            <Utils.Button containerClassName={style.buttonAddContainer} format="square" /*fullWidth={false}*/ onClick={handleAddChamp}>
              Ajouter un champ ({value.fields.length}/{fieldLimit})
            </Utils.Button>
          </div>
        )}
      </div>

      <ModalChoice
        ref={choicesInputRef}
        isModalChoiceOpen={isModalChoiceOpen}
        setIsModalChoiceOpen={setIsModalChoiceOpen}
        value={value}
        setValue={setValue}
        setShowUpdateMessage={setShowUpdateMessage}
        setFocus={setFocus}
      />

      <ModalDescription
        ref={descriptionTextAreaRef}
        isModalDescriptionOpen={isModalDescriptionOpen}
        setIsModalDescriptionOpen={setIsModalDescriptionOpen}
        value={value}
        setValue={setValue}
        setShowUpdateMessage={setShowUpdateMessage}
        setFocus={setFocus}
      />

      <Utils.Modal isOpen={isModalViewOpen} onClose={() => setIsModalViewOpen(false)} closable title="Aperçu de la rubrique">
        <>
          <FormFields category={value} />
        </>
      </Utils.Modal>

      <Utils.Modal isOpen={isModalMediaOpen} onClose={() => setIsModalMediaOpen(false)} closable title="Utilisation de médias">
        Pour utiliser des médias dans vos rubriques vous devez être abonné à{" "}
        <span className={style.link} onClick={() => window.open("https://inari.c-koya.tech/apps")}>
          Photos & Vidéos
          <img src={`${MY_DOMAIN}/img/media-icon.png`} style={{ height: "1.5rem", width: "1.5rem", marginLeft: "0.5rem" }} />
        </span>
      </Utils.Modal>
    </div>
  );
}
