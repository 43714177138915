import React from 'react';

// import style from './List.module.css';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode;
}

export default function List(props: Props): React.ReactElement {
  return <div {...props}>{props.children}</div>;
}
