import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components, DropdownIndicatorProps, GroupBase, NoticeProps, OptionsOrGroups } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

import "./StyledSelect.css";

interface Props extends StateManagerProps {
  noOptionsText?: string;
}

export default function StyledSelect(props: Props) {
  const DropdownIndicator = (dropdownProps: DropdownIndicatorProps<unknown, true>) => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        <FontAwesomeIcon icon={faCaretDown} color="black" />
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = (noticeProps: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...noticeProps}>
        <span>{props.noOptionsText}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Select
      placeholder="Sélectionner..."
      // noOptionsMessage={{ NoOptionsMessage } as any}
      {...props}
      classNamePrefix={"react-styled-select"}
      components={{ DropdownIndicator, NoOptionsMessage, ...props.components } as any}
    />
  );
}
