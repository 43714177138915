import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";

export function detailledProposalsSteps(history: any, setOnBoardingStart?: (index: number) => void): ShepherdOptionsWithType[] {
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Traitement des propositions</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 9;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "detailled-proposals-1st",
      attachTo: { element: ".onboarding-detailledproposals-1st", on: "right" },
      advanceOn: { selector: ".onboarding-detailledproposals-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/proposal");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
      Cliquez sur l'onglet <span class="important-text">Propositions</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-2nd",
      attachTo: { element: ".onboarding-detailledproposals-2nd", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        Vous pouvez filtrer les <span class="important-text">propositions</span> en utilisant les options ci-présentes.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-3rd",
      attachTo: { element: ".onboarding-detailledproposals-3rd", on: "right" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-detailledproposals-3rd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        Ceci est une <span class="important-text">proposition</span>.<br/>
        C'est depuis ici que vous pouvez les traiter, en changeant le statut et en y répondant.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-6th",
      attachTo: { element: ".onboarding-detailledproposals-6th", on: "left" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        Vous pouvez changer son <span class="important-text">statut</span> en le sélectionnant ici.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-5th",
      attachTo: { element: ".onboarding-detailledproposals-5th", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        Sa couleur permet de connaître son <span class="important-text">statut</span>.<br/>
        <span class="important-text-yellow">En attente</span> - <span class="important-text">En cours</span> - <span class="important-text-green">Retenue</span> - <span class="important-text-red">Non-retenue</span>
      `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-7th",
      attachTo: { element: ".onboarding-detailledproposals-7th", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        Le contenu de la <span class="important-text">proposition</span> se trouve dans cette zone.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-4th",
      attachTo: { element: ".onboarding-detailledproposals-4th", on: "top" },
      canClickTarget: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">7 sur ${stepsLength}</span><br/>
        Vous pouvez choisir de la supprimer en appuyant sur l'icône <span class="important-text-black">Poubelle</span> <img src="${MY_DOMAIN}/onboarding/trash-solid.svg" width="14px" height="14px" />.<br/>
        <span class="important-text-red">Attention, c'est définitif !</span>`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-8th",
      attachTo: { element: ".onboarding-detailledproposals-8th", on: "left" },
      advanceOn: { selector: ".onboarding-detailledproposals-8th", event: "click" },
      canClickTarget: true,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-proposals-next-1st"]');
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">8 sur ${stepsLength}</span><br/>
        Appuyez sur <span class="important-text">répondre</span> afin de traiter la proposition.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-9th",
      attachTo: { element: ".onboarding-detailledproposals-9th", on: "left" },
      advanceOn: { selector: ".onboarding-detailledproposals-9th-submit", event: "click" },
      canClickTarget: true,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            var element = document.querySelector('svg[id="onboarding-detailledproposals-previous"]');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-proposals-next-9th"]');
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">9 sur ${stepsLength}</span><br/>
        Vous n'avez plus qu'à traiter cette proposition<br/>
        selon votre choix.<br/>
        <br/>
        Appuyez sur le bouton <span class="important-text">répondre</span> pour valider.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-10th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history.push("/?onBoardingStart=5");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel sur le <span class="important-text">"Tableau d'étiquettes virtuelles"</span>.`,
      ],
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}

/*





*/
