import { checkStatus, host, url_core } from "../api";
import { AUTH_METHOD, PRIVACY } from "../constantes";

import { Company, SmallUser } from "./_type";

interface StatsResponse {
  userCount: number;
  answerCount: number;
  answerCountLastWeek: number;
  answerCountLastMonth: number;
  answerCountLastYear: number;
  answerEnAttente: number;
  answerEnCours: number;
  answerNonRetenue: number;
  answerRetenue: number;
  lastMonth: [{ userAnswerCount: number; author?: string }];
  lastWeek: [{ userAnswerCount: number; author?: string }];
  lastYear: [{ userAnswerCount: number; author?: string }];
}

const companies = {
  getMyCompany: (): Promise<Company> => {
    return fetch(`${host}/api/companies`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyUsers: (): Promise<SmallUser[]> => {
    return fetch(`${host}/api/companies/users`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateCompany: (body: { privacy?: PRIVACY; authMethod?: AUTH_METHOD }): Promise<Company> => {
    return fetch(`${host}/api/companies`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getStats: (): Promise<StatsResponse> => {
    return fetch(`${host}/api/companies/stats`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default companies;
