import React, { useEffect } from 'react';
import { url_core } from '../../utils/api';

import { MY_DOMAIN, parseHash } from '../../utils/utils';

export default function List(): React.ReactElement {
  useEffect(() => {
    let hash = parseHash();
    let redirect = hash.get('redirect') || '/';

    window.location.href = `${url_core}/appLogin?client_id=${
      process.env.REACT_APP_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      `${MY_DOMAIN}/return-login`
    )}&redirect_page=${encodeURIComponent(redirect)}`;
  }, []);

  return <></>;
}
