import { checkStatus, host } from '../api';

const status = {
  deleteStatus: (id: number): Promise<string> => {
    return fetch(`${host}/api/status/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('jwt') || ''),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default status;
