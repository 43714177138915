import { useEffect, useState } from 'react';
import api from '../../utils/api';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { UserContextType, useUser } from '../../utils/context/User';
import {
  HasCheckLoginContextType,
  useHasCheckLogin,
} from '../../utils/context/HasCheckLogin';
import { JWT_PERIOD } from '../../utils/constantes';
import { useHistory } from 'react-router';
import { parseQuery } from '../../utils/utils';

export default function ReturnLogin() {
  const history = useHistory();
  const [isError, setIsError] = useState('');
  const [, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  useEffect(() => {
    const hash = parseQuery();

    if (hash.get('code') === null) {
      setIsError('Vous devez rentrer un code sur cette route');
    } else {
      api.loginApi
        .exchangeCode({ code: hash.get('code') as string })
        .then((data) => {
          localStorage.setItem('jwt', data.jwt);

          api.loginApi
            .getMe()
            .then((data) => {
              localStorage.setItem('jwt', data.jwt);

              setHasCheckLogin(true);
              setUser(data.user);

              setTimeout(refetchMe, JWT_PERIOD);

              history.push(hash.get('redirect_page') as string);
            })
            .catch((err) => {
              console.log(err);
              setIsError(JSON.parse(err.message).message);
              setUser(undefined);
            });
        })
        .catch((err) => {
          setIsError('Le code que vous avez rentré est invalide');
        });
    }

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          console.log('%cAcualisation du JWT', 'color: blue');

          localStorage.setItem('jwt', data.jwt);

          setHasCheckLogin(true);
          setUser(data.user);

          setTimeout(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setIsError(JSON.parse(err.message).message);
          setUser(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isError !== '' ? (
    <Container>
      <Alert variant="danger">{isError}</Alert>
    </Container>
  ) : (
    <></>
  );
}
