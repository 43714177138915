import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import style from "./Input.module.css";

import classNames from "classnames";
import HelpIcon from "../../HelpIcon/HelpIcon";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string;
  label?: React.ReactNode;
  placeholder?: string;
  subtext?: React.ReactNode;
  icon?: IconDefinition;
  helpTitle?: string;
  helpContent?: string;
  hidable?: boolean;
  isInvalid?: boolean;
  isInvalidMessage?: string;
  inverted?: boolean;
  width?: string;
  colorReverse?: boolean;
  clear?: () => void;
  invalidColor?: string | null;
}

const IconInputHidable = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      invalidColor = "var(--red)",
      type,
      label,
      subtext,
      icon,
      helpTitle,
      helpContent,
      hidable,
      isInvalid,
      isInvalidMessage,
      inverted,
      width,
      colorReverse,
      clear,
      ...props
    }: Props,
    ref
  ) => {
    const [isInputShown, setIsInputShown] = useState(false);

    function clearButton() {
      return (
        clear !== undefined &&
        props.value &&
        props.value.toString().length > 0 && (
          <FontAwesomeIcon
            title={"Vider le champ"}
            icon={faTimes}
            color="#B4B4B4"
            className={classNames(style.icon, style.clearableCross)}
            onClick={clear}
          />
        )
      );
    }

    return (
      <div>
        {!!label ? (
          <div className={style.labelContainer}>
            <label className={style.label}>{label}</label>
            {helpContent !== undefined && (
              <div style={{ position: "relative", marginLeft: "0.5rem" }}>
                <HelpIcon
                  title={helpTitle}
                  content={helpContent}
                  iconSize="0.75rem"
                  containerSize="1.5rem"
                  width="30rem"
                  left="-15rem"
                  bottom="40px"
                />
              </div>
            )}
          </div>
        ) : null}
        <div
          className={classNames(style.iconInputContainer, {
            [style.isInvalid]: isInvalid,
            [style.haveLabel]: !!label,
            [style.haveSubText]: !!subtext,
            [style.inverted]: inverted === true,
            [style.colorReverse]: colorReverse === true,
          })}
          style={{ width: width, border: isInvalid ? `1px solid #${invalidColor}` : "unset" }}
        >
          {icon !== undefined ? <FontAwesomeIcon icon={icon} color="#B4B4B4" className={style.icon} /> : null}

          {inverted && clearButton()}
          <input {...props} ref={ref} type={isInputShown || hidable !== true ? type : "password"} className={style.iconInput} />
          {!inverted && clearButton()}

          {hidable === true ? (
            <FontAwesomeIcon
              icon={isInputShown ? faEyeSlash : faEye}
              color="#B4B4B4"
              className={style.iconEye}
              onClick={() => setIsInputShown((before) => !before)}
            />
          ) : null}
        </div>
        {!!isInvalidMessage ? <p className={style.isInvalidText}>{isInvalidMessage}</p> : null}
        {!!subtext ? <p className={style.subtext}>{subtext}</p> : null}
      </div>
    );
  }
);

export default IconInputHidable;
