import { PageItem } from "./_type";
import { host, checkStatus } from "../api";

import { Answer } from "./_type";

import qs from "qs";
import { FILTER_OPTION } from "../../components/Utils/Sort/Sort";

const answers = {
  addAnswers: (body: { category: number; answerItems: { field: number; value: string | boolean | File }[]; freeField?: string }): Promise<Answer> => {
    return fetch(`${host}/api/answers`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAnswer: ({ id }: { id: number }): Promise<Answer> => {
    return fetch(`${host}/api/answers/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteAnswer: (id: number): Promise<string> => {
    return fetch(`${host}/api/answers/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  addAnswersWithToken: ({
    token,
    body,
  }: {
    token: string;
    body: {
      answerItems: { field: number; value: string | boolean }[];
      freeField?: string;
    };
  }): Promise<Answer> => {
    return fetch(`${host}/api/answers/${token}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAllAnswers: ({
    page,
    filters,
  }: {
    page: number;
    filters: {
      global: string;
      category: {
        value: string[];
        sort: FILTER_OPTION;
      };
      status: {
        value: string[];
        sort: FILTER_OPTION;
      };
      creator: {
        value: string;
        sort: FILTER_OPTION;
      };
      createdAtFrom: {
        value: string;
        sort: FILTER_OPTION;
      };
      createdAtTo: {
        value: string;
        sort: FILTER_OPTION;
      };
      responseBy: {
        value: string;
        sort: FILTER_OPTION;
      };
    };
  }): Promise<PageItem<Answer>> => {
    return fetch(`${host}/api/answers?${qs.stringify({ page, ...filters })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAnswersReadonly: ({
    token,
    page,
    filters,
  }: {
    token: string;
    page: number;
    filters: {
      global: string;
    };
  }): Promise<PageItem<Answer>> => {
    return fetch(`${host}/api/answers/readonly/${token}?${qs.stringify({ page, ...filters })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateAnswer: ({ answerId, statusId }: { answerId: number; statusId: number }): Promise<Answer> => {
    return fetch(`${host}/api/answers/${answerId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ status: statusId }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  addResponse: ({
    answerId,
    response,
    fields,
  }: {
    answerId: number;
    response: string;
    fields: { [key: string]: string | boolean };
  }): Promise<Answer> => {
    return fetch(`${host}/api/answers/${answerId}/response`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify({ answer: { response: response }, fields: fields }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updatePiloteFields: ({ id, fields }: { id: number; fields: { [key: string]: string | boolean } }): Promise<Answer> => {
    return fetch(`${host}/api/answers/${id}/pilote-fields`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
        "content-type": "application/json",
      },
      body: JSON.stringify(fields),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  postAnswerFile: ({ id, field, file }: { id: number; field: number; file: File }): Promise<Answer> => {
    let data = new FormData();
    data.append("field", field.toString());
    data.append("file", file);
    return fetch(`${host}/api/answers/${id}/file`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default answers;
