import React, { useState, useEffect } from "react";

import Utils from "../../../components/Utils";
import { Field, PostCategory } from "../../../utils/api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { arrayMove, arrayReplace } from "../../../utils/utils";
import { useMutation } from "react-query";
import api from "../../../utils/api";
import classNames from "classnames";

import style from "../RubriqueForm.module.css";

interface ModalChoiceProps {
  isModalChoiceOpen: Field | undefined;
  setIsModalChoiceOpen: React.Dispatch<React.SetStateAction<Field | undefined>>;
  value: PostCategory;
  setValue: (value: React.SetStateAction<PostCategory>) => void;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalChoice = React.forwardRef<HTMLInputElement, ModalChoiceProps>(
  ({ isModalChoiceOpen, setIsModalChoiceOpen, value, setValue, setShowUpdateMessage, setFocus }: ModalChoiceProps, ref) => {
    const [choiceInput, setChoiceInput] = useState("");
    const [renamingChoice, setRenamingChoice] = useState("");

    const { mutate: updateChoices } = useMutation(api.fields.updateChoices, {
      onSuccess: () => {
        setShowUpdateMessage(true);
      },
    });

    const { mutate: addChoice } = useMutation(api.fields.addChoice, {
      onSuccess: () => {
        setShowUpdateMessage(true);
      },
    });

    const { mutate: deleteChoice } = useMutation(api.fields.deleteChoice, {
      onSuccess: () => {
        setShowUpdateMessage(true);
      },
    });

    const { mutate: renameChoice } = useMutation(api.fields.renameChoice, {
      onSuccess: () => {
        setShowUpdateMessage(true);
      },
    });

    function handleChoiceOrderChange(index: number, parentField: Field, increment: number) {
      if (index < 0) return;
      if (parentField === undefined || parentField.properties.choices === undefined) return;

      let newParentField: Field = parentField;

      newParentField.properties.choices = arrayMove(parentField.properties.choices, index, index + increment);

      setValue((b) => ({ ...b, fields: arrayReplace(b.fields, value.fields.indexOf(parentField), newParentField) }));

      if (value.id !== undefined) {
        updateChoices({ id: newParentField.id, choices: newParentField.properties.choices });
      }
    }

    function handleAddChoice() {
      if (choiceInput === "" || isModalChoiceOpen === undefined || isModalChoiceOpen.properties.choices?.includes(choiceInput)) return;

      setValue((b) => ({
        ...b,
        fields: b.fields.map((f) => {
          return f.id === isModalChoiceOpen.id
            ? {
                ...f,
                properties: {
                  ...f.properties,
                  choices: [...f.properties.choices!, choiceInput],
                },
              }
            : f;
        }),
      }));

      setIsModalChoiceOpen((b) =>
        b === undefined
          ? b
          : {
              ...b,
              properties: {
                ...b.properties,
                choices: [...b.properties.choices!, choiceInput],
              },
            }
      );

      if (value.id !== undefined) {
        addChoice({
          id: isModalChoiceOpen.id,
          choice: choiceInput,
        });
      }

      setChoiceInput("");
      setFocus((p) => !p);
    }

    function handleDeleteChoice(choice: string) {
      if (isModalChoiceOpen === undefined) return;

      setValue((b) => ({
        ...b,
        fields: b.fields.map((f) =>
          f.id === isModalChoiceOpen.id
            ? {
                ...f,
                properties: {
                  ...f.properties,
                  choices: f.properties.choices!.filter((c) => c !== choice),
                },
              }
            : f
        ),
      }));

      setIsModalChoiceOpen((b) =>
        b === undefined
          ? b
          : {
              ...b,
              properties: {
                ...b.properties,
                choices: b.properties.choices!.filter((c) => c !== choice),
              },
            }
      );

      if (value.id !== undefined) {
        deleteChoice({
          id: isModalChoiceOpen.id,
          choice: choice,
        });
      }
    }

    function handleRenameChoice() {
      if (isModalChoiceOpen === undefined) return;

      setValue((b) => ({
        ...b,
        fields: b.fields.map((f) =>
          f.id === isModalChoiceOpen.id
            ? {
                ...f,
                properties: {
                  ...f.properties,
                  choices: f.properties.choices!.map((c) => (c !== renamingChoice ? c : choiceInput)),
                },
              }
            : f
        ),
      }));

      setIsModalChoiceOpen((b) =>
        b === undefined
          ? b
          : {
              ...b,
              properties: {
                ...b.properties,
                choices: b.properties.choices!.map((c) => (c !== renamingChoice ? c : choiceInput)),
              },
            }
      );

      if (value.id !== undefined) {
        renameChoice({
          id: isModalChoiceOpen.id,
          oldChoice: renamingChoice,
          newChoice: choiceInput,
        });
      }

      setRenamingChoice("");
      setChoiceInput("");
    }

    return (
      <Utils.Modal isOpen={isModalChoiceOpen !== undefined} closable onClose={() => setIsModalChoiceOpen(undefined)} title="Liste des choix">
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {isModalChoiceOpen?.properties.choices?.map((choice, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    flex: 1,
                    height: "3px",
                    borderBottom: "2px solid var(--dot-border)",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isModalChoiceOpen.properties.choices && (
                      <span style={{ display: "inline-block", marginRight: "2rem" }}>
                        <Utils.UpDown
                          canGoUp={index !== 0}
                          canGoDown={index !== isModalChoiceOpen.properties.choices.length - 1}
                          goUp={() => handleChoiceOrderChange(index, isModalChoiceOpen, -1)}
                          goDown={() => handleChoiceOrderChange(index, isModalChoiceOpen, +1)}
                        />
                      </span>
                    )}
                    <span>{choice}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon
                      icon={faPen}
                      onClick={() => {
                        setRenamingChoice(choice);
                        setChoiceInput(choice);
                      }}
                      className={style.deleteIcon}
                      style={{ marginLeft: "2rem" }}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() =>
                        !(isModalChoiceOpen.properties.choices && isModalChoiceOpen.properties.choices.length <= 1) && handleDeleteChoice(choice)
                      }
                      className={classNames(
                        style.deleteIcon,
                        isModalChoiceOpen.properties.choices && isModalChoiceOpen.properties.choices.length <= 1 && style.notAllowedCursor
                      )}
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          <Utils.Input
            ref={ref}
            value={choiceInput}
            onChange={(e) => setChoiceInput(e.target.value)}
            label={renamingChoice ? "Modifier le choix (" + renamingChoice + ")" : "Ajouter un choix"}
            isInvalid={choiceInput === "" || isModalChoiceOpen?.properties.choices?.includes(choiceInput)}
            autoFocus
          />
          {renamingChoice ? (
            <div
              style={{
                marginTop: "-0.625rem",
                display: "flex",
                justifyContent: "center",
                gap: ".625rem",
              }}
            >
              <Utils.Button onClick={() => setRenamingChoice("")} format="square" variant="gray" fullWidth={false}>
                Annuler
              </Utils.Button>{" "}
              <Utils.Button
                onClick={handleRenameChoice}
                format="square"
                fullWidth={false}
                disabled={choiceInput === "" || isModalChoiceOpen?.properties.choices?.includes(choiceInput)}
              >
                Modifier le choix
              </Utils.Button>
            </div>
          ) : (
            <Utils.Button
              onClick={handleAddChoice}
              format="square"
              fullWidth={false}
              disabled={choiceInput === "" || isModalChoiceOpen?.properties.choices?.includes(choiceInput)}
            >
              Ajouter le choix
            </Utils.Button>
          )}
        </>
      </Utils.Modal>
    );
  }
);

export default ModalChoice;
