import classNames from 'classnames';
import style from './StepSeparator.module.css';

interface Props {
  hasText?: boolean;
}

export default function StepSeparator({ hasText = false }: Props) {
  return (
    <div
      className={classNames(style.stepSeparator, { [style.hasText]: hasText })}
    />
  );
}
