import { faArchive, faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Answer, AnswerItem } from "../../utils/api/_type";
import { dateToString } from "../../utils/utils";
import Utils from "../../components/Utils";
import React from "react";

import style from "./ListProposals.module.css";

interface Props {
  answer: Answer;
  getCreator: (answer: Answer) => JSX.Element | "Anonyme";
  setOpenedDelete: React.Dispatch<React.SetStateAction<Answer | undefined>>;
  formatColor: (answer: Answer) => string | undefined;
  changeProposalStatus: (value: any, idAnswer: number) => void;
  formatAnswerItem: (answerItem?: AnswerItem) => string | JSX.Element | undefined;
  openProposal: (answer: Answer) => void;
  answerResponse: string;
  setAnswerResponse: React.Dispatch<React.SetStateAction<string>>;
  openedProposal?: Answer;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  setOpenedProposal: React.Dispatch<React.SetStateAction<Answer | undefined>>;
  handleResponse: () => void;
  isOpenedProposalModalOpen: boolean;
}

export default function ProposalComponent({
  answer,
  getCreator,
  setOpenedDelete,
  formatColor,
  changeProposalStatus,
  formatAnswerItem,
  openProposal,
  answerResponse,
  setAnswerResponse,
  openedProposal,
  setOpenedProposal,
  handleResponse,
  isOpenedProposalModalOpen,
}: Props) {
  function isMiniSelected() {
    return openedProposal === answer && !isOpenedProposalModalOpen;
  }

  function openModal() {
    !isMiniSelected() && openProposal(answer);
  }

  function reverseCard() {
    !isMiniSelected() ? setOpenedProposal(answer) : setOpenedProposal(undefined);
  }

  return (
    <>
      <div
        key={answer.id}
        className={classNames(style.flipCard, isMiniSelected() && style.flipHover, style.gridCard, "onboarding-detailledproposals-3rd")}
        onClick={() => openModal()}
      >
        <div className={style.gridCardTitle}>
          <p className={style.creatorName}>{getCreator(answer)}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!answer.archived && (
              <FontAwesomeIcon
                title="Archiver la proposition"
                icon={faArchive}
                className={classNames(style.actionIcon, "onboarding-detailledproposals-4th")}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setOpenedDelete(answer);
                }}
              />
            )}
            <div className={classNames(style.colorIndicator, formatColor(answer), "onboarding-detailledproposals-5th")} />
          </div>
        </div>
        <p className={style.createdAt}>{dateToString(answer.createdAt)}</p>
        <div className={classNames(style.categoryName, style.gridCategoryName)}>{answer.category.name}</div>
        {!answer.archived ? (
          <Utils.Select
            value={answer.status.id}
            onChange={(e) => changeProposalStatus(e, answer.id)}
            className={classNames(style.gridSelect, "onboarding-detailledproposals-6th")}
          >
            {answer.category.status.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </Utils.Select>
        ) : (
          <div style={{ margin: "10px 0" }}>
            <Utils.Input className={classNames(style.gridSelect, "onboarding-detailledproposals-6th")} value={"Archivée"} disabled />
          </div>
        )}
        <div className={classNames(style.flipCardInner, style.proposalContent, "onboarding-detailledproposals-7th")}>
          {!isMiniSelected() ? (
            <div className={style.flipCardFront}>
              <Utils.List className={classNames(style.gridList)}>
                {answer.answerItems.map((answerItem) => (
                  <Utils.ListItem key={answerItem.id} text={answerItem.field.name}>
                    <Utils.ListSubItem stopXOverflow>{formatAnswerItem(answerItem)}</Utils.ListSubItem>
                  </Utils.ListItem>
                ))}
              </Utils.List>
            </div>
          ) : (
            <div className={style.flipCardBack}>
              <Utils.Textarea
                // helpIcon={faQuestionCircle}
                // helpContent="Votre réponse au sujet de la proposition."
                label="Réponse"
                value={answerResponse}
                onChange={(e) => setAnswerResponse(e.target.value)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                rows={4}
                resizable={false}
              />
            </div>
          )}
        </div>

        <div className={style.bottomButtons}>
          <span className={style.responseBy}>
            {answer.response && !isMiniSelected() && `Répondu par ${answer.responseBy?.firstname} ${answer.responseBy?.lastname}`}
          </span>
          {!answer.archived &&
            (isMiniSelected() ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Utils.Button
                  variant="red"
                  format="square"
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    reverseCard();
                  }}
                  containerStyle={{
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    marginRight: "1rem",
                    wordBreak: "keep-all",
                    marginTop: "0",
                  }}
                >
                  Annuler <FontAwesomeIcon icon={faTimes} />
                </Utils.Button>

                <Utils.Button
                  variant="green"
                  format="square"
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleResponse();
                    reverseCard();
                  }}
                  containerStyle={{
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    wordBreak: "keep-all",
                    marginTop: "0",
                  }}
                >
                  Valider <FontAwesomeIcon icon={faCheck} />
                </Utils.Button>
              </div>
            ) : (
              <Utils.Button
                id="onboarding-proposals-next-1st"
                className="onboarding-detailledproposals-8th"
                variant="primary"
                format="square"
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  reverseCard();
                }}
                containerStyle={{
                  justifyContent: "flex-end",
                  marginBottom: "0px",
                  wordBreak: "keep-all",
                  marginTop: "0",
                }}
              >
                Répondre
              </Utils.Button>
            ))}
        </div>
      </div>
    </>
  );
}
