import { FILTER_OPTION } from "../../components/Utils/Sort/Sort";
import { FIELDS_TYPE, PRIVACY, AUTH_METHOD } from "../constantes";

export interface SmallUser {
  id: number;
  firstname: string;
  lastname: string;
}

export interface MidUser extends SmallUser {
  email: string;
}

export enum ROLES {
  ROLE_COMPANY_ADMIN = "ROLE_COMPANY_ADMIN",
  ROLE_APPLICATION_ADMIN = "ROLE_APPLICATION_ADMIN",
  ROLE_APPLICATION_ACCESS = "ROLE_APPLICATION_ACCESS",
}

export interface User extends MidUser {
  createdAt: string;
  roles: ROLES[];
  company: SmallCompany;
  username: string;
}

export interface SmallCompany {
  id: number;
  name: string;
  token: string;
  properties?: {
    access?: {
      capture?: number;
      kaizen?: number;
      catalogue?: number;
    };
  };
}

export interface Company extends SmallCompany {
  users: SmallUser[];
  privacy: string;
  authMethod: string;
}

export interface SmallCategory {
  id: number;
  name: string;
  token: string;
  status: Status[];
  categoryPilotes: CategoryPilote[];
  createdAt: string | null;
  creator: SmallUser | null;
  customText: string | null;
  color: string | null;
  color2nd: string | null;
  showLogo: boolean | null;
  noticeAnswer: boolean | null;
  isActive: boolean | null;
}

export interface Category extends SmallCategory {
  company: SmallCompany;
  fields: Field[];
  answers: Answer[];
  privacy: PRIVACY | "none";
  authMethod: AUTH_METHOD | "none";
  defaultStatus: Status | null;
  description: string;
}

export interface PostCategory
  extends Omit<
    Category,
    | "id"
    | "token"
    | "status"
    | "categoryPilotes"
    | "company"
    | "answers"
    | "defaultStatus"
    | "createdAt"
    | "creator"
    | "active"
    | "customText"
    | "color"
    | "color2nd"
    | "showLogo"
    | "noticeAnswer"
  > {
  id?: number;
  token?: string;
  status?: Status[];
  categoryPilotes: CategoryPilote[];
  company?: SmallCompany;
  answers?: Answer[];
  defaultStatus?: Status | null;
  createdAt?: string | null;
  creator?: SmallUser | null;
  active?: boolean | null;
  customText?: string | null;
  color?: string | null;
  color2nd?: string | null;
  showLogo?: boolean | null;
  noticeAnswer?: boolean | null;
}

// export interface PostCategory extends Omit<Category, "id" | "token" | "status" | "categoryPilotes" | "company" | "answers" | "defaultStatus"> {
//   piloteIds: number[];
// }

// export interface PostField extends Omit<Field, "id"> {}

export interface Status {
  id: number;
  name: string;
}

export interface CategoryPilote {
  id: number;
  user: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

export interface SmallField {
  id: number;
}

export interface Field extends SmallField {
  name: string;
  type: FIELDS_TYPE;
  required: "unrequired" | "required" | "pilote";
  properties: { choices?: string[]; description?: string };
  position: number;
  isFavorite: boolean | null;
}

export interface Answer {
  id: number;
  createdAt: string;
  creator: MidUser;
  answerItems: AnswerItem[];
  status: Status;
  freeField: null | string;
  response: null | string;
  responseAt: null | string;
  responseBy: null | MidUser;
  category: SmallCategory;
  archived: Date | null;
}

export interface AnswerItem {
  id: number;
  value: string;
  field: Field;
  type: FIELDS_TYPE;
}

export interface SelectedField {
  id: number;
  field: Field;
  position: number | null;
}

export interface Pagination {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;
}

export interface PageItem<T> {
  pagination: Pagination;
  items: T[];
}

export interface UserOption {
  value: number;
  label: string;
  color?: string;
}

export interface UserCategory {
  id: number;
  category: {
    id: number;
  };
  user: {
    id: number;
    firstname: string;
    lastname: string;
  };
  type: string;
}

export interface ICategoriesFilter {
  global: string;
  isActive: {
    value: string;
    sort: FILTER_OPTION;
  };
  category: {
    value: string;
    sort: FILTER_OPTION;
  };
  creator: {
    value: string;
    sort: FILTER_OPTION;
  };
  createdAt: {
    value: string;
    sort: FILTER_OPTION;
  };
}

export interface SmallApplication {
  id: number;
  name: string;
  fileUri: string | null;
}

export interface Patchnote {
  id: number;
  title: string;
  content: string;
  type: string;
  application: SmallApplication;
  scheduledAt: string;
}

export interface Tag {
  label: string;
  color: string;
}
