import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import style from "./TopBar.module.css";

import { ReactComponent as UserCircle } from "../../../svg/user-circle.svg";
import { ReactComponent as OnboardingCircle } from "../../../svg/onboarding-circle.svg";
import { ReactComponent as CaretDown } from "../../../svg/caret-down.svg";
import { ReactComponent as PowerOff } from "../../../svg/power-off.svg";
import { UserContextType, useUser } from "../../../utils/context/User";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { isAdmin, startOnBoardingTour } from "../../../utils/utils";

interface Props {
  username?: string;
  userRole?: string;
  brandLogoSrc: string;
  brandLogoClick?: () => void;
  brandLogoText?: string;
}

export default function TopBar(props: Props) {
  const hideTopbar = ["/appLogin", "/proposal/"];
  const notLoggedRoute = ["/login", "/register", "/"];
  const menu = useRef<any>(null);
  const menuButton = useRef<any>(null);
  const onboarding = useRef<any>(null);
  const onboardingButton = useRef<any>(null);

  const [user, setUser] = useUser() as UserContextType;
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isOnboardingOpen, setIsOnBoardingOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target) && menuButton.current && !menuButton.current.contains(e.target)) {
      toggleMenu();
    }
    if (onboarding.current && !onboarding.current.contains(e.target) && onboardingButton.current && !onboardingButton.current.contains(e.target)) {
      toggleOnboarding();
    }
  };

  function toggleOnboarding() {
    setIsOnBoardingOpen((before) => !before);
  }

  function toggleMenu() {
    setIsMenuOpen((before) => !before);
  }

  function logout() {
    localStorage.removeItem("jwt");
    setUser(undefined);
    setIsMenuOpen(false);
    setIsOnBoardingOpen(false);
    queryClient.clear();

    if (!notLoggedRoute.includes(location.pathname) && !location.pathname.startsWith("/c/")) {
      history.push("/login#redirect=" + location.pathname);
    }
  }

  function startOnBoardingTour(index: number) {
    toggleOnboarding();
    history.push(`/?onBoardingStart=${index}`);
  }

  return user !== undefined && !hideTopbar.some((loc) => location.pathname.match(loc)) ? (
    <>
      <div className={style.topbar}>
        <div className={style.brandLogo} onClick={props.brandLogoClick}>
          <img className={style.brandLogoIcon} src={props.brandLogoSrc} alt="Logo" />
          <span className={style.brandLogoText}>{props.brandLogoText}</span>
        </div>
        <div className={style.rightItems}>
          {isAdmin(user) && (
            <div className={style.onboarding} ref={onboardingButton} onClick={toggleOnboarding}>
              <OnboardingCircle className={classNames(style.icon, style.circle)} />
            </div>
          )}

          <div className={style.userInfoContainer}>
            <span className={style.userName}>{props.username}</span>
            <span className={style.userRole}>{props.userRole}</span>
          </div>
          <div className={style.collapseButton} ref={menuButton} onClick={toggleMenu}>
            <UserCircle className={classNames(style.icon, style.circle)} />
            <CaretDown
              className={classNames(style.icon, style.caretDown, {
                [style.reverseCaretDown]: isMenuOpen === true,
              })}
            />
          </div>
          {isMenuOpen && (
            <div className={style.menu} ref={menu}>
              <p
                onClick={() => {
                  if (process.env.REACT_APP_URL_CORE) {
                    window.location.href = process.env.REACT_APP_URL_CORE;
                  }
                }}
              >
                Retour aux apps
              </p>
              {/* <div className={style.separator}></div> */}
              <p className={style.disconnect} onClick={logout}>
                <PowerOff />
                Déconnexion
              </p>
            </div>
          )}
          {isOnboardingOpen && (
            <div className={classNames([style.onboardingMenu])} ref={onboarding}>
              <span className={style.textContent}>Découvrez les tutoriels</span>
              <div className={style.itemList}>
                <div className={style.listItem} onClick={() => startOnBoardingTour(0)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Créer une rubrique
                </div>
                <div className={style.listItem} onClick={() => startOnBoardingTour(1)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Options d'une rubrique
                </div>
                <div className={style.listItem} onClick={() => startOnBoardingTour(2)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Partager une rubrique
                </div>
                {/* <div className={style.listItem} onClick={() => startOnBoardingTour(3)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Remonter une idée
                </div> */}
                <div className={style.listItem} onClick={() => startOnBoardingTour(4)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Traitement des propositions
                </div>
                <div className={style.listItem} onClick={() => startOnBoardingTour(5)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Tableau étiquette virtuelle
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.fakeTopbar}></div>
    </>
  ) : null;
}
