import { ReactComponentElement, useState } from "react";
import { ReactComponent as Question } from "../../svg/question.svg";
import style from "./HelpIcon.module.css";

interface Props {
  content?: string;
  title?: string;
  containerColor?: string;
  containerHoverColor?: string;
  containerSize?: string;
  iconColor?: string;
  iconHoverColor?: string;
  iconSize?: string;
  width?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

export default function HelpIcon(props: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <>
      <div
        className={style.container}
        style={{
          backgroundColor: isHover ? props.containerHoverColor : props.containerColor,
          width: props.containerSize,
          height: props.containerSize,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Question
          className={style.question}
          style={{ color: isHover ? props.iconHoverColor : props.iconColor, width: props.iconSize, height: props.iconSize }}
        />
      </div>

      {isHover && (
        <div
          className={style.contentContainer}
          style={{
            width: props.width,
            left: props.left,
            right: props.right,
            bottom: props.bottom,
            top: props.top,
          }}
        >
          {props.title && <div className={style.title}>{props.title}</div>}
          <div className={style.content}>{props.content}</div>
        </div>
      )}
    </>
  );
}
