import cssStyle from './ImportantNumber.module.css';

interface Props {
  number: number;
  word: string;
  wordPlural?: string;
  style?: React.CSSProperties;
}

export default function ImportantNumber({
  number,
  word,
  wordPlural,
  style,
}: Props) {
  return (
    <p className={cssStyle.applicationNumber} style={style}>
      <span className={cssStyle.applicationNumberColor}>{number}</span>{' '}
      <span className={cssStyle.word}>
        {wordPlural === undefined
          ? word + (number > 1 ? 's' : '')
          : number > 1
          ? wordPlural
          : word}
      </span>
    </p>
  );
}
