import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import style from "./SortMenu.module.css";

interface Props {
  title: string;
  children: React.ReactNode;
  clear?: () => void;
  className?: string;
}

export default function SortMenu({ title, children, clear, className }: Props) {
  return (
    <div className={classNames(style.sortMenu, className)}>
      <div className={style.title}>
        <p>{title}</p>
        {clear !== undefined && <FontAwesomeIcon title={"Réinitialiser les filtres"} icon={faEraser} onClick={clear} />}
      </div>
      {children}
    </div>
  );
}
