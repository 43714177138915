import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "toastify-js/src/toastify.css";

import Modal from "react-modal";

import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";

import { ReactQueryDevtools } from "react-query/devtools";

import { JWT_PERIOD } from "./utils/constantes";
import api from "./utils/api";

import { UserContextType, useUser } from "./utils/context/User";
import { HasCheckLoginContextType, useHasCheckLogin } from "./utils/context/HasCheckLogin";

// Composants
import NeedLogin from "./components/NeedLogin";

// Pages
import Login from "./pages/Auth/Login";
import ListCategories from "./pages/ListCategories";
import ReturnLogin from "./pages/Auth/ReturnLogin";
import Utils from "./components/Utils";
import RubriqueForm from "./pages/RubriqueForm/RubriqueForm";
import ListProposals from "./pages/ListProposals/ListProposals";
import FormProposal from "./pages/FormProposal/FormProposal";
import ReadonlyProposal from "./pages/ReadonlyProposal/ReadonlyProposal";
import Stats from "./pages/Stats/Stats";
import { ROLES } from "./utils/api/_type";
import Error from "./pages/Error/Error";
import { formatUserRoleToString, MY_DOMAIN } from "./utils/utils";
import Home from "./pages/Home/Home";

import { ShepherdOptionsWithType, ShepherdTour } from "react-shepherd"; // Outils pour les onboarding tour

Modal.setAppElement("#root");

function App() {
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;
  const history = useHistory();

  useEffect(() => {
    api.loginApi
      .getMe()
      .then((data) => {
        console.log("%cReconexion avec le JWT", "color: blue");
        // history.push();
        setUser(data.user);
        setHasCheckLogin(true);
        refetchMe();
      })
      .catch((err) => {
        console.log(err);
        setHasCheckLogin(true);
        setUser(undefined);
      });

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          console.log("%cAcualisation du JWT", "color: blue");

          localStorage.setItem("jwt", data.jwt);

          setUser(data.user);

          setTimeout(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setUser(undefined);
        });
    }
  }, [setUser, setHasCheckLogin]);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
        label: "fg",
      },
    },
    useModalOverlay: true,
    exitOnEsc: true,
    keyboardNavigation: false,
  };

  return (
    <>
      <ShepherdTour steps={[]} tourOptions={tourOptions}>
        <Utils.TopBar
          brandLogoSrc={`${MY_DOMAIN}/kaizenLogo.png`}
          brandLogoClick={() => (window.location.href = "/")}
          brandLogoText="Boite à idées digitale"
          userRole={user && formatUserRoleToString(user)}
          username={user && user.firstname + " " + user.lastname.substring(0, 1) + "."}
        />

        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/return-login">
            <ReturnLogin />
          </Route>

          <Route path="/c/:token">
            <FormProposal />
          </Route>

          <Route path="/stats">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <Stats />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <Route path="/categories/:id/edit">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <RubriqueForm />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <Route path="/categories/create">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <RubriqueForm />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <Route path="/categories">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <ListCategories />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <Route path="/proposal/:token">
            <ReadonlyProposal />
          </Route>

          <Route path="/proposal">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <ListProposals />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <Route path="/error">
            <Error />
          </Route>

          <Route exact path="/">
            <NeedLogin roles={[ROLES.ROLE_COMPANY_ADMIN, ROLES.ROLE_APPLICATION_ADMIN]}>
              <Utils.SidePannel>
                <Home />
              </Utils.SidePannel>
            </NeedLogin>
          </Route>

          <NeedLogin>
            {user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) ? (
              <Redirect to="/proposal" />
            ) : (
              <Redirect to="/proposal/readonly" />
            )}
          </NeedLogin>
        </Switch>
      </ShepherdTour>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
