import classNames from "classnames";
import React from "react";

import style from "./ListSubItem.module.css";

interface Props {
  children?: React.ReactNode;
  stopXOverflow?: boolean;
}

export default function ListSubItem({ children, stopXOverflow }: Props): React.ReactElement {
  return <div className={classNames(style.text, stopXOverflow && style.stopXoverflow)}>{children}</div>;
}
