import { useEffect, useRef, useState } from "react";
import style from "./Select.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, IconDefinition } from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames";
import PopOver from "../PopOver/PopOver";
import _ from "lodash";
import { useOutsideClickDetection } from "../../../utils/react-utils";

interface Props {
  children: (React.ReactElement | React.ReactElement[])[];
  value?: string | number;
  onChange?: (newValue: string) => void;
  label?: React.ReactNode;
  isInvalid?: boolean;
  invalidColor?: string | null;
  className?: string;
  helpIcon?: IconDefinition;
  helpContent?: string;
}

export default function Select({ invalidColor = "var(--red)", ...props }: Props) {
  // SOIT si une valeur est passée, on cherche dans le tableau des children lequel a comme valeur la bonne et on prend son contenu
  // SOIT on prend le contenu du premier children
  const [value, setValue] = useState(
    props.children.flat().find((child) => child.props.value === props.value)?.props.children || props.children.flat()[0].props.children
  );

  useEffect(() => {
    setValue(props.children.flat().find((child) => child.props.value === props.value)?.props.children || props.children.flat()[0].props.children);
  }, [props.children, props.value]);

  function handleClickValue(e: any, value: string) {
    e.stopPropagation();

    // On cherche dans les children quel est celui qui a la bonne valeure et on prend son contenu
    setValue(props.children.flat().find((child) => child.props.value === value)?.props.children);
    setIsDropdownOpen(false);

    if (props.onChange !== undefined) props.onChange(value);
  }

  // Gestion de l'ouverture et de la fermeture
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonMenu = useRef<any>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (buttonMenu.current && !buttonMenu.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    setIsDropdownOpen((b) => !b);
  }

  useOutsideClickDetection(buttonMenu, handleClickOutside);

  return (
    <div>
      {props.label !== undefined ? (
        <div className={style.labelContainer}>
          <label className={style.label}>{props.label}</label>
          {props.helpIcon !== undefined && (
            <PopOver content={props.helpContent}>
              <FontAwesomeIcon icon={props.helpIcon} className={style.helpIcon} />
            </PopOver>
          )}
        </div>
      ) : null}

      <div
        id={_.uniqueId("select-button-")}
        className={classNames(
          style.select,
          {
            [style.isInvalid]: props.isInvalid,
          },
          props.className
        )}
        style={{ border: props.isInvalid ? `1px solid #${invalidColor}` : "unset" }}
        onClick={(e) => handleClick(e)}
        ref={buttonMenu}
      >
        <p>{value}</p>
        <FontAwesomeIcon icon={faCaretDown} />
        <div
          id={_.uniqueId("select-menu-")}
          className={classNames(style.dropDown, {
            [style.opened]: isDropdownOpen,
          })}
        >
          {props.children.flat().map((child, index) => (
            <div className={style.option} key={index} onClick={(e) => handleClickValue(e, child.props.value)}>
              {child.props.children}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
