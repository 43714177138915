import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import styleClass from "./Card.module.css";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  title?: string;
  titleStyle?: React.CSSProperties;
  titleCenter?: boolean;
  width?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
  leftElements?: React.ReactNode;
  className?: string;
}

export default function Card({ children, title, titleStyle, titleCenter, width, isLoading, style, leftElements, className }: Props) {
  return (
    <div className={classNames(styleClass.card, className)} style={{ width: width, ...style }}>
      {isLoading ? (
        <div className={styleClass.loaderContainer}>
          <FontAwesomeIcon className={styleClass.icon} icon={faCircleNotch} />
        </div>
      ) : null}
      {title !== undefined ? (
        <div className={styleClass.titleContainer}>
          <h1
            className={classNames(styleClass.title, {
              [styleClass.titleCenter]: titleCenter,
            })}
            style={titleStyle}
          >
            {title}
          </h1>
          {leftElements}
        </div>
      ) : null}
      {children}
    </div>
  );
}
