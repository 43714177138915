import classNames from "classnames";
import React, { useState } from "react";

import style from "./PopOver.module.css";

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
}

export default function PopOver({ children, content, className }: Props) {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  return (
    <div className={classNames(className, style.popOver)} onMouseEnter={() => setIsPopOverOpen(true)} onMouseLeave={() => setIsPopOverOpen(false)}>
      {isPopOverOpen ? <div className={style.popOverContent}>{content}</div> : null}
      {children}
    </div>
  );
}
