import classNames from "classnames";
import style from "./Checkbox.module.css";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: React.ReactNode;
  click?: React.MouseEventHandler<HTMLLabelElement>;
  isInvalid?: boolean;
  invalidColor?: string | null;
}

const defaultColor = "e74c3c";

export default function Checkbox({ invalidColor, label, click, isInvalid, ...props }: Props) {
  if (!invalidColor) {
    invalidColor = defaultColor;
  }
  return (
    <label
      className={classNames(style.checkbox, {
        [style.isInvalid]: isInvalid,
      })}
      style={{ color: isInvalid ? `1px solid #${invalidColor}` : "unset" }}
      onClick={click}
    >
      <input
        style={{
          border: props.checked ? "none" : `1px solid ${isInvalid ? "#" + invalidColor : "var(--unselected-icon)"}`,
          backgroundColor: props.checked ? (invalidColor !== defaultColor ? `#${invalidColor}` : "var(--primary-color)") : "unset",
        }}
        type="checkbox"
        readOnly={!click}
        {...props}
      />
      {label}
    </label>
  );
}
