import style from './Step.module.css';

interface Props {
  children: React.ReactNode;
}

export default function Step(props: Props) {
  return (
    <div className={style.stepContainer}>
      <div className={style.step}>{props.children}</div>
    </div>
  );
}
