import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Utils from "../../components/Utils";
import { Answer, AnswerItem } from "../../utils/api/_type";
import { dateToString } from "../../utils/utils";
import { FormInputObject } from "./ListProposals";
import * as Yup from "yup";
import React from "react";

import style from "./ListProposals.module.css";

interface FPI_Props {
  fakeProposal: Answer;
  getCreator: (answer: Answer) => JSX.Element | "Anonyme";
  renderAnswerItem: (answerItem: AnswerItem) => JSX.Element;
  next?: boolean;
}

function FakeProposalItem({ fakeProposal, getCreator, renderAnswerItem, next }: FPI_Props) {
  return (
    <Utils.Card
      className={classNames(style.nextOrPreviousProposal, next ? style.nextProposal : style.previousProposal)}
      style={{ marginTop: "10px" }}
    >
      <div className={style.creatorName}>{getCreator(fakeProposal)}</div>
      <div className={style.createdAt}>{dateToString(fakeProposal.createdAt)}</div>
      <div className={style.categoryName}>{fakeProposal.category.name}</div>
      <Utils.List>
        {fakeProposal.answerItems
          .filter((answerItem) => answerItem.field.required !== "pilote")
          .map((answerItem) => (
            <React.Fragment key={answerItem.id}>{renderAnswerItem(answerItem)}</React.Fragment>
          ))}
      </Utils.List>
      <Utils.Input disabled value={fakeProposal.status.name} label="Statut" />
      <Utils.Textarea resizable={false} label="Réponse" value={fakeProposal.response || ""} disabled style={{ resize: "none" }} />
      {fakeProposal.responseBy && (
        <p className={style.responseBy}>
          Répondu par {fakeProposal.responseBy.firstname} {fakeProposal.responseBy.lastname}
        </p>
      )}
    </Utils.Card>
  );
}

interface PI_Props {
  openedProposal: Answer;
  getCreator: (answer: Answer) => JSX.Element | "Anonyme";

  answerStatus?: number;
  changeProposalStatus: (value: any, idAnswer: number) => void;
  answerResponse: string;
  setAnswerResponse: React.Dispatch<React.SetStateAction<string>>;
  handleResponse: () => void;
  isLoadingAddResponse: boolean;
  renderAnswerItem: (answerItem: AnswerItem) => JSX.Element;
}

function ProposalItem({
  openedProposal,
  getCreator,
  answerStatus,
  changeProposalStatus,
  answerResponse,
  setAnswerResponse,
  handleResponse,
  isLoadingAddResponse,
  renderAnswerItem,
}: PI_Props) {
  return (
    <Utils.Card className={classNames(style.proposalItemCard, "onboarding-detailledproposals-9th")} style={{ marginTop: "10px" }} width={"40%"}>
      <div>
        <div className={style.creatorName}>{getCreator(openedProposal)}</div>
        <div className={style.createdAt}>{dateToString(openedProposal.createdAt)}</div>
        <div className={style.categoryName}>{openedProposal.category.name}</div>
        {/* la liste margin-top: 25px normalement */}
        <Utils.List>
          {openedProposal.answerItems
            .filter((answerItem) => answerItem.field.required !== "pilote" || answerItem.field.type === "title")
            .map((answerItem) => (
              <React.Fragment key={answerItem.id}>{renderAnswerItem(answerItem)}</React.Fragment>
            ))}
          {openedProposal.answerItems
            .filter((answerItem) => answerItem.field.required === "pilote" && answerItem.field.type !== "title")
            .map((answerItem) => (
              <React.Fragment key={answerItem.id}>{renderAnswerItem(answerItem)}</React.Fragment>
            ))}
        </Utils.List>
        {!openedProposal.archived ? (
          <Utils.Select
            value={answerStatus || openedProposal.status.id}
            onChange={(e) => changeProposalStatus(e, openedProposal.id)}
            label="Statut"
            className="onboarding-detailledproposals-6th"
          >
            {openedProposal.category.status.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </Utils.Select>
        ) : (
          <div style={{ margin: "10px 0" }}>
            <Utils.Input className={classNames(style.gridSelect, "onboarding-detailledproposals-6th")} label="Statut" value={"Archivée"} disabled />
          </div>
        )}
        <Utils.Textarea
          label="Réponse"
          value={answerResponse}
          onChange={(e) => setAnswerResponse(e.target.value)}
          resizable={false}
          disabled={!!openedProposal.archived}
        />

        {openedProposal.responseBy && (
          <p className={style.responseBy}>
            Répondu par {openedProposal.responseBy.firstname} {openedProposal.responseBy.lastname}
          </p>
        )}
      </div>
      {!openedProposal.archived && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Utils.Button
            id="onboarding-proposals-next-9th"
            className="onboarding-detailledproposals-9th-submit"
            fullWidth={false}
            format="square"
            onClick={handleResponse}
            isLoading={isLoadingAddResponse}
          >
            Envoyer
          </Utils.Button>
        </div>
      )}
    </Utils.Card>
  );
}

interface Props {
  answers: Answer[];
  isOpenedProposalModalOpen: boolean;
  setIsOpenedProposalModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openedProposal?: Answer;
  setOpenedProposal: React.Dispatch<React.SetStateAction<Answer | undefined>>;
  getCreator: (answer: Answer) => JSX.Element | "Anonyme";
  formState: FormInputObject;
  handleInput: (e: any) => void;
  resetForm: (newValue?: FormInputObject | undefined) => void;
  validationSchema: Yup.ObjectSchema<any>;
  formatAnswerItem: (answerItem?: AnswerItem) => string | JSX.Element | undefined;
  answerStatus?: number;
  setAnswerStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
  changeProposalStatus: (value: any, idAnswer: number) => void;
  answerResponse: string;
  setAnswerResponse: React.Dispatch<React.SetStateAction<string>>;
  handleResponse: () => void;
  isLoadingAddResponse: boolean;
}

export default function ModalAnswer({
  answers,
  isOpenedProposalModalOpen,
  setIsOpenedProposalModalOpen,
  openedProposal,
  setOpenedProposal,
  getCreator,
  formState,
  handleInput,
  resetForm,
  validationSchema,
  formatAnswerItem,
  answerStatus,
  setAnswerStatus,
  changeProposalStatus,
  answerResponse,
  setAnswerResponse,
  handleResponse,
  isLoadingAddResponse,
}: Props) {
  function canOpenNextProposal(): boolean {
    return getOpenedProposalIndex() < answers.length - 1;
  }

  function canOpenPreviousProposal(): boolean {
    return getOpenedProposalIndex() > 0;
  }

  function openNextOrPreviousProposal(next: boolean) {
    setAnswerStatus(undefined);

    let index = getOpenedProposalIndex();
    index = next ? index + 1 : index - 1;

    if (index >= 0 && index < answers.length) {
      setOpenedProposal(answers[index]);
    } else if (answers.length > 1 && index === -1) {
      setOpenedProposal(answers[answers.length - 1]);
    } else if (answers.length > 1 && index === answers.length) {
      setOpenedProposal(answers[0]);
    }
  }

  function openNextProposal() {
    openNextOrPreviousProposal(true);
  }
  function openPreviousProposal() {
    openNextOrPreviousProposal(false);
  }

  function getOpenedProposalIndex() {
    let index = 0;
    answers.find((answer, aIndex) => {
      if (answer.id === openedProposal?.id) {
        index = aIndex;
        return true;
      }
      return false;
    });

    return index;
  }

  function handleSelectInput(id: number, val: string) {
    resetForm(((b: any) => ({ ...b, [id + ""]: val })) as any);
  }

  function renderAnswerItem(answerItem: AnswerItem) {
    if (answerItem.field.required === "pilote" && answerItem.field.type !== "title") {
      const field = answerItem.field;

      return (
        <>
          {field.type === "bool" ? (
            <Utils.Checkbox
              key={field.id}
              checked={formState[field.id + ""] as boolean}
              onChange={handleInput}
              label={
                <>
                  {field.name} {field.required === "required" ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}{" "}
                </>
              }
              isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
              name={field.id + ""}
            />
          ) : field.type === "date" ? (
            <Utils.Input
              key={field.id}
              label={
                <>
                  {field.name} {field.required === "required" ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}{" "}
                </>
              }
              type="date"
              name={field.id + ""}
              value={new Date(formState[field.id] as string).toISOString().split("T")[0]}
              isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
              onChange={handleInput}
            />
          ) : field.type === "int" ? (
            <Utils.Input
              key={field.id}
              label={
                <>
                  {" "}
                  {field.name} {field.required === "required" ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}{" "}
                </>
              }
              type="number"
              name={field.id + ""}
              value={formState[field.id + ""] as string}
              onChange={handleInput}
              isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
            />
          ) : field.type === "string" ? (
            <Utils.Input
              key={field.id}
              label={
                <>
                  {" "}
                  {field.name} {field.required === "required" ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}{" "}
                </>
              }
              type="string"
              name={field.id + ""}
              value={formState[field.id + ""] as string}
              onChange={handleInput}
              isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
            />
          ) : field.type === "select" ? (
            <Utils.Select
              key={field.id}
              label={
                <>
                  {field.name} {field.required === "required" ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : null}{" "}
                </>
              }
              value={formState[field.id + ""] as string}
              onChange={(val) => handleSelectInput(field.id, val)}
              isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
            >
              {field.properties.choices!.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Utils.Select>
          ) : null}
        </>
      );
    } else {
      return (
        <Utils.ListItem text={answerItem.field.name}>
          <Utils.ListSubItem>{formatAnswerItem(answerItem)}</Utils.ListSubItem>
        </Utils.ListItem>
      );
    }
  }

  return (
    <>
      <Utils.Modal
        isOpen={isOpenedProposalModalOpen}
        onClose={() => {
          setIsOpenedProposalModalOpen(false);
          setOpenedProposal(undefined);
        }}
        closable
        title={"Répondre"}
        width={"90vw"}
        containerClassName={style.modalContainer}
        childrenStyle={style.modalAnswer}
        closeButtonID="onboarding-detailledproposals-previous"
      >
        <Utils.Divider variant="gray" />
        <Utils.HorizontalContainer verticalCenter={true} style={{ position: "relative", justifyContent: "center", overflow: "hidden" }}>
          <div
            className={classNames(style.modalActionIcon, {
              [style.disable]: answers.length <= 1,
            })}
            onClick={openPreviousProposal}
            style={{ left: "3rem" }}
          >
            <FontAwesomeIcon
              title={"Proposition précédente"}
              icon={faArrowLeft}
              className={classNames(style.arrow, {
                [style.disable]: answers.length <= 1,
              })}
            />
          </div>

          <div></div>
          {answers.length > 1 && (
            <FakeProposalItem
              fakeProposal={canOpenPreviousProposal() ? answers[getOpenedProposalIndex() - 1] : answers[answers.length - 1]}
              getCreator={getCreator}
              renderAnswerItem={renderAnswerItem}
            />
          )}

          {openedProposal !== undefined && (
            <ProposalItem
              openedProposal={openedProposal}
              getCreator={getCreator}
              answerStatus={answerStatus}
              changeProposalStatus={changeProposalStatus}
              answerResponse={answerResponse}
              setAnswerResponse={setAnswerResponse}
              handleResponse={handleResponse}
              isLoadingAddResponse={isLoadingAddResponse}
              renderAnswerItem={renderAnswerItem}
            />
          )}

          {answers.length > 1 && (
            <FakeProposalItem
              fakeProposal={canOpenNextProposal() ? answers[getOpenedProposalIndex() + 1] : answers[0]}
              getCreator={getCreator}
              renderAnswerItem={renderAnswerItem}
              next
            />
          )}

          <div
            className={classNames(style.modalActionIcon, {
              [style.disable]: answers.length <= 1,
            })}
            onClick={openNextProposal}
            style={{ right: "3rem" }}
          >
            <FontAwesomeIcon
              title={"Proposition suivante"}
              icon={faArrowRight}
              className={classNames(style.arrow, {
                [style.disable]: answers.length <= 1,
              })}
            />
          </div>
        </Utils.HorizontalContainer>
      </Utils.Modal>
    </>
  );
}
