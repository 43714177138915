import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../../utils/utils";

export function createCategorySteps(history: any): ShepherdOptionsWithType[] {
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Créer une nouvelle rubrique</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 6;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "create-categories-1st",
      attachTo: { element: ".onboarding-categories-1st", on: "right" },
      advanceOn: { selector: ".onboarding-categories-1st", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/categories");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-1st",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "fg",
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
       Cliquez sur l'onglet <span class="important-text">Rubriques</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, -100] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-2nd",
      attachTo: { element: ".onboarding-categories-2nd", on: "left-end" },
      advanceOn: { selector: ".onboarding-categories-2nd", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            history.push("/categories/create");
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
       Cliquez sur le bouton <span class="important-text">Créer une rubrique</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-3rd",
      attachTo: { element: ".onboarding-categories-3rd", on: "top-end" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            history.push("/categories");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-categories-next"]');
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-3rd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
      Ici, donnez un <span class="important-text">nom</span> et une <span class="important-text">description</span> à votre rubrique.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [-50, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-5th",
      attachTo: { element: ".onboarding-categories-5th", on: "left" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            var element = document.querySelector('button[id="create-categories-previous"]');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-categories-next"]');
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-5th",
      highlightClass: "onboarding-categories-5th-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/><br/>
        Vous pouvez définir la <span class="important-text">méthode d'authentification</span>.<br/>
        - <span class="important-text-black">Anonyme</span> : Permet un accès sans connexion et sans informations personnelles.<br/>
        - <span class="important-text-black">Libre</span> : Permet un accès sans connexion mais le nom de l'auteur est demandé.<br/>
        - <span class="important-text-black">Connecté</span> : Permet un accès uniquement depuis un compte connecté.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [-50, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-7th",
      attachTo: { element: ".onboarding-categories-7th", on: "top-end" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            var element = document.querySelector('button[id="create-categories-previous"]');
            simulateMouseClick(element);
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-7th",
      highlightClass: "onboarding-categories-7th-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        Ici, vous pouvez ajouter et paramétrer des <span class="important-text">champs</span>. Ces <span class="important-text">champs</span> s'afficheront dans votre formulaire de remontée d'idée.<br/>
        sur votre rubrique.<br/><br/>
        L'icône <span class="important-text-yellow">Coeur</span> <img src="${MY_DOMAIN}/onboarding/heart-solid.svg" width="14px" height="14px" /> vous permet de choisir le <span class="important-text">champ</span> qui sera affiché dans le <span class="important-text">tableau d'étiquette virtuelle</span>.<br/>
        L'icône <span class="important-text">Aperçu</span> <img src="${MY_DOMAIN}/onboarding/eye-solid.svg" width="14px" height="14px" /> vous permet d'avoir un aperçu du formulaire de soumission d'idées.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-8th",
      attachTo: { element: ".onboarding-categories-8th", on: "right-start" },
      advanceOn: { selector: ".onboarding-categories-8th", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector('button[id="onboarding-categories-next-8th"]');
            simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-8th",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
      Enfin, appuyez sur <span class="important-text-yellow">Terminer</span> et votre rubrique sera créée.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-9th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            history.push("/?onBoardingStart=1");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     history.push("/categories/create");
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">créer une nouvelle rubrique</span> !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel <span class="important-text">"Options d'une rubrique"</span>.`,
      ],
      when: {
        cancel: function () {
          history.push("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
